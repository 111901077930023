

.editprofile
{
	padding-top: 50px;  
	padding-bottom: 50px;  
	&-avatar
	{
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		justify-content: center;
		&__btn
		{
			position: static!important;
			color: #ffffff!important;
			cursor: pointer;
		}
		img
		{
			width: 180px;
			height: 180px;
			object-fit: cover;
			border-radius: 50%;
			-webkit-box-shadow: 0px 10px 0px 0px rgba(194,194,194,1);
			-moz-box-shadow: 0px 10px 0px 0px rgba(194,194,194,1);
			box-shadow: 0px 10px 0px 0px rgba(194,194,194,1);
		}
	}
	h2 
	{
		font-size: 48px;
		margin-top: 0;
		margin-bottom: 10px;
		
	}
	&-buttons
	{
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
	}
	&-results, &-edit
	{
		@include button($blue, $bluehover); 
		border: none;
	}
	&-links
	{
		display: flex;
		justify-content: space-between;
		font-size: 50px;
		width: 200px;
		max-width: 100%;
		margin: 10px;
		color: grey;
		transform: scale(1.0);
		transition: .3s all ease;
		a
		{
			&.profile-youtube 
			{
				color: rgb(214, 0, 0);
			}
			&.profile-twitch
			{
				color: #9147ff;
			}
			&.profile-steam 
			{
				color: #000;
			}
			&:hover 
			{
				transition: .3s all ease;
				transform: scale(1.1);
			}
		}
	}
	&-form 
	{
		flex-wrap: wrap;
	}
	&-modal
	{
		background: rgba(0, 0, 0, 0.356);
		position: fixed;
		top:0;
		width: 100vw;
		height: 100%;
		z-index: 100;
		display: flex;
		justify-content: center;
		align-items: center;
		&-item
		{
			max-width: 90%;
			background: #7289da;
			width: 30rem;
			padding: 2rem;
			border-radius: 10px;
			h2
			{
				margin-top: 0;
			}
			h2, h3
			{
				color: #ffffff;
			}
		}
	}
}