.form
	{
		display: flex;
		align-items: flex-start;
		min-height: 40px;
		padding: 40px;
		
		&-item
		{
            color: var(--eblack);
			flex: 1 1 300px;
            position: relative;
			padding: 0 10px;
			margin-bottom: 20px;
			&.align-start
			{
				align-self: start;
			}
			label
				{
					top: 10px;
					left: 15px;
					font-size: 15px;
					font-weight: 600;
					font-family: "OpenSans-SemiBold";
					position: absolute;

					font-size: 20px;
					color: #959595;
                    transition: all .2s ease;
                    &.file
                    {
                        position: static;
                        color: $blue;
                        text-transform: uppercase;
                        font-family: "OpenSans-Bold";
                        &:active
                        {
                            transition: .2s ease all;
                            background: rgba(153, 153, 153, 0.2);
                        }
                    }
                }

                span
                    {
                        font-family: "OpenSans-SemiBold";
                        margin-top: 15px;
                    }
			&__input
				{
                    flex: 1 1 120px;
                    background: none;
					border: none;
					border-bottom: 3px solid grey;
					width: 90%;
					padding: 7px 7px 7px 7px;
					font-size: 20px;
					font-family: "OpenSans-SemiBold";
					position: relative;

					&:focus
					{
						border: none;
						color: $blue;
						border-bottom: 3px solid grey;
                    }
                    &.file
                    {
                        display: none;
                    }
                    &#sum
                    {
                        -moz-appearance:textfield;
                        &::-webkit-outer-spin-button,
                        &::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                        }
                    }
				}
			&__textarea
				{
                    flex: 1 1 120px;
                    background: none;
					border: none;
					border-bottom: 3px solid grey;
					width: 90%;
					padding: 7px 7px 7px 7px;
					font-size: 20px;
					font-family: "OpenSans-SemiBold";
					position: relative;
                    margin-bottom: -3px;
					&:focus
					{
						border: none;
						outline: 0;
						color: $blue;
						border-bottom: 3px solid grey;
                    }
                    &.file
                    {
                        display: none;
                    }

				}
				&:after
					{
						content:'';
						width: calc(90% - 20px);
						display: block;
						height: 10px;
						position: absolute;
                        bottom: 0px;
                        left: 10px;
                        right: 10px;
						padding: 0 10px;
						margin-left: 0;
						margin-right: 0;
						border-bottom: 3px solid $blue;
						transform-origin: center center;
						transform: scaleX(0);
						z-index: 3;
						transition: all .2s ease;
					}
			&.active
			{
				label
					{
						top: -10px;
						font-size: 12px;
						transition: all .3s ease;
						color: $blue;
                        z-index: 3;
					}
				.form-item__input
				{
					&:focus
					{

						outline: none;
					}
				}
				&:after
				{
					transform: scaleX(1);
					transition: all .3s ease;
				}
			}
			&.valid
			{
				color: #00a331!important;
				label
					{
						color: #00a331!important;
					}
					.form-item__input
					{
						&:focus
						{
							color: #00a331!important;

							outline: none;
						}
					}
				&:after
				{
					border-bottom: 3px solid #00a331;
					color:#00a331!important;
				}
			}
			&.invalid
			{
				color: #a30000!important;
				label
					{
						color: #a30000!important;
					}
					.form-item__input
					{
						&:focus
						{
							color: #a30000!important;

							outline: none;
						}
					}
				&:after
				{
					border-bottom: 3px solid #a30000;
					color:#a30000!important;
				}
			}
			&-track
			{
				margin-bottom: 30px;
			}
			&-file
			{
				display: flex;
				position: relative;
				align-items: center;
				span
				{
					margin-right: 30px;
					font-weight: 600;
				}
				&__input
				{
					position: absolute;
					z-index: -1;
					opacity: 0;
				}
			}
		}
		form
		{
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			flex-direction: column;
			align-items: center;

			input
			{
				margin: 10px;
				position: relative;


				&[type="submit"]
				{
					@include button($blue, $bluehover);
					display: block;
					border-width: 0px;
					border-style: none;
					border-color: none;
					border-image: none;
				}
			}
		}
		p
		{
			text-align: center;
			font-family: "OpenSans-Regular";
			font-size: 14px;
			color: #959595;
			a
			{
				@include link($blue, $bluehover);
			}
		}
		&-row
		{
			display: flex;
		}
		&-calendar
		{
			display: block;
		}
	}
