.series
{
    a
    {
        text-decoration: none;
    }
    &-list
    {
        margin-top: 2rem;
        &-item
        {
            box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 5px 2px;
            height: 18rem;
            width: 100%;
            margin-bottom: 1.5rem;
            position: relative;
            overflow: hidden;

            @media screen and (max-width: 568px)
            {
                height: 28rem;

            }
            &-naming
            {
                display: flex;
                justify-content: space-between;
                @media screen and (max-width: 568px)
                {
                    flex-direction: column;
                }
            }
            &__logo
            {
                position: relative;
                z-index: 2;
                margin-top: 1rem;
                margin-right: 1rem;
                max-width: 100%;
                width: 16rem;
            }
            &__img
            {
                object-fit: cover;
                width: 100%;
                height: 100%;
                position: absolute;
                z-index: 0;
            }
            &__h2
            {
                display: block;
                text-shadow: 0px 0px 4px rgba(0, 0, 0, 1);
                font-size: 2.5rem;
                margin-left: 2rem;
                color: #ffffff;
                position: relative;
                z-index: 3;
                @media screen and (max-width: 568px)
                {
                    font-size: 2rem!important;
                }
            }
            &__teams
            {
                color: #ffffff;
                font-weight: 600;
                font-size: 1rem;
                text-transform: uppercase;
                background: #930006;
                display: block;
                padding: 0.5rem 0.5rem 0.5rem 1rem;
                margin-bottom: 1rem;
                width: fit-content;
                position: absolute;

                bottom: 4rem;
                &::before
                {
                    content:"\A";
                    border-style: solid;
                    border-width: 2rem 1rem 0rem 0;
                    border-color: #930006 transparent transparent transparent;
                    position: absolute;
                    right: -1rem;
                    top: 0;
                }
            }
            &__events
            {
                color: #ffffff;
                font-weight: 600;
                font-size: 1rem;
                text-transform: uppercase;
                background: #4778ce;
                display: block;
                padding: 0.5rem 0.5rem 0.5rem 1rem;
                margin-bottom: 1rem;
                width: fit-content;
                position: absolute;
                bottom: 1rem;
                &::before
                {
                    content:"\A";
                    border-style: solid;
                    border-width: 2rem 1rem 0rem 0;
                    border-color: #4778ce transparent transparent transparent;
                    position: absolute;
                    right: -1rem;
                    top: 0;
                }
            }
            &__link
            {
                position: absolute;
                text-transform: uppercase;
                bottom: 2rem;
                right: 2rem;
                font-size: 2rem;
                padding: 0.5rem 1rem;
                box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 5px 3px;
                @media screen and (max-width: 568px)
                {
                    right: 1rem;
                }
            }
        }
    }
    &-item
    {
        box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.3);
        border-radius: 3px;
        margin-bottom: 1.5rem;
        transition: all .3s ease;
        position: relative;
        &.prize
            {
                background: #254d92;
                h4
                {
                    color: #eee;
                }
                h5
                {
                    color: #142138;
                }
                .series-item-content-drivers
                {
                    color: #142138;
                }
            }
        &-img
        {
            position: relative;
            overflow: hidden;
            &.passed
            {
                img
                {
                    -webkit-filter: grayscale(70%) blur(1px);
                    filter: grayscale(70%) blur(1px);
                }
            }
            img
            {
                object-fit: cover;
                width: 100%;
                height: 15rem;
                transition: .3s ease;
            }
        }
        &-h3
        {
            position: absolute;
            bottom: 0;
            left: 1rem;
            color: #fafafa;
            font-weight: 600;
            font-size: 1.4rem;
            text-shadow: 2px 2px 2px #000;
        }
        h5
        {
            margin-top: 0;
            margin-bottom: 0.3rem;
            color: $blue;
        }
        &:hover
        {
                    transform: scale(1.05);
                    transition: all .3s ease;
        }
        &__completed
        {
            position: absolute;
            top: 0;
            left:0%;

            -webkit-filter: grayscale(0%) blur(0.7px)!important;
            filter: grayscale(0%) blur(0.7px)!important;
        }
        &__serieslogo
        {
            position: absolute;
            top: 2rem;
            right: -4rem;
            max-width: 15rem !important;
            transform: rotateZ(45deg);
            height: auto !important;
            padding: 0 4rem;
            background: #4778ce;
        }
        &__sa
        {
            color: #ffffff;
            font-weight: 600;
            font-size: 1rem;
            text-transform: uppercase;
            background: #930006;
            display: block;
            padding: 0.5rem 0.5rem 0.5rem 1rem;
            margin-bottom: 1rem;
            width: -webkit-fit-content;
            width: -moz-fit-content;
            width: fit-content;
            position: absolute;
            right: 0;
            bottom: 0.5rem;
            &::before
            {
                content:"\A";
                border-style: solid;
                border-width: 2rem 0rem 0rem 1rem;
                border-color: #930006 transparent transparent transparent;
                position: absolute;
                left: -1rem;
                top: 0;
            }
        }
        &-content
        {
            padding: 0 1rem 1rem;
            position: relative;
            &__flag
            {
                width: 1.2rem;
                height: 1.2rem;
                border-radius: 40%;
                margin-left: 1.3rem;
                -webkit-box-shadow: 1px 1px 4px 0px rgba(0,0,0,0.75);
                -moz-box-shadow: 1px 1px 4px 0px rgba(0,0,0,0.75);
                box-shadow: 1px 1px 4px 0px rgba(0,0,0,0.75);
            }
            &-drivers
            {
                position: absolute;
                top: -10px;
                right: 1rem;
                font-size: 2rem;
                color: $blue;
            }
        }
        &-entries
        {
            background: #4778ce;
            padding: 0.2rem 0.4rem;
            font-size: 0.9rem;
            border-radius: 0.3rem;
            color: #fff;
            position: absolute;
            top: 1rem;
            // left: 1em;
            left: 1rem;
        }
        &-followers
        {
            background: var(--egreen);
            padding: 0.2rem 0.4rem;
            font-size: 0.9rem;
            border-radius: 0.3rem;
            color: #fff;
            position: absolute;
            top: 3rem;
            left: 1rem;

            // left: 4%;
        }
    }
    &-drivers
    {
        border-radius: 5px;
        // background: #fafafa;
        margin-bottom: 30px;
    }
    &-driver
    {
        border-radius: 10px;
        padding: 15px;
        transition: all .3s ease;
        margin-bottom: 5px;
        a
        {
            display: flex;
            align-items: center;
        }
        &:hover
        {
            background: rgb(177, 177, 177);
            transition: all .3s ease;
        }
        &-name
        {
            text-align: center;
            display: block;
            margin: 0 0 0 20px;
        }
        &-avatar
        {
            flex-basis: 35px;
            img
            {
                width: 30px;
                height: 30px;
                -o-object-fit: cover;
                object-fit: cover;
                border-radius: 50%;
                box-shadow: 0px 2px 0px 0px #c2c2c2;
            }
        }
    }
    &-team
    {
        &-edit
        {
            display: none;
        }
        &-link
        {
            height: 100px;
            display: block;
            margin: auto;
        }
        &__p
        {
            text-align: justify;
            font-family: 'OpenSans-Regular';
        }
        &__logo
        {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

    }
    &-car
    {

        h4
        {
            text-align: right;
        }
        &__img
        {
            max-width: 100%;
        }
        &__icon
        {
            max-width: 1.2rem;
            margin: 0 0.3rem 0 1rem;
        }
    }




        &-information
        {
            // height: calc(100% - 40px);
            border: none;
            transition: .3s all;
            max-width: 100%;
            margin-right: 25px;
            margin-top: 20px;
            margin-bottom: 20px;
            padding: 80px 20px 20px 40px;
            flex-wrap: wrap;
            justify-content: flex-start;
            background: var(--einfo-label);
            position: relative;
            border-radius: 18px;
            -webkit-box-shadow: var(--elabel-shadow);
            -moz-box-shadow: var(--elabel-shadow);
            box-shadow: var(--elabel-shadow);
            &-content
            {
                display: flex;
                flex-wrap: wrap;
                justify-content: start;
            }
            &__item
            {
                flex: 1 1 280px;
                max-width: 327px;
                margin-bottom: 15px;
            }
            &__name
            {
                font-weight: 600;
            }
            .fa-check-circle
            {
                font-size: 20px;
                color: green;
            }
            .fa-times-circle
            {
                font-size: 20px;
                color: red;
            }
        }
        &-type
        {
            position: absolute;
            top: -30px;
            left: -6px;
            font-family: 'OpenSans-ExtraBold';
            font-size: 80px;
            color: var(--ebg-color);
        }
        &-add-btn
    {
        position: absolute;
        bottom: 3.5rem;
        right: 0;
    }
    &-not-confurmed
    {
        color: #dcdc00;
        font-size: 1.4rem;
    }
}
