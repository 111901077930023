.category
{
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.5rem;
    padding: 0.2rem 0.5rem;
    margin-bottom: 0.1rem;
    display: inline-block;
    &.cat-no_cat
    {
        background: #4778ce;
        color: #ffffff;
    }
    
    &.cat-am
    {
        background: rgb(192, 0, 0);
        color: #ffffff;
    }

    &.cat-silver
    {
        background: rgb(100, 100, 100);
        color: #ffffff;
    }

    &.cat-proam
    {
        background: rgb(15, 15, 15);
        color: #ffffff;
    }

    &.cat-pro
    {
        background: #ffffff;
        color: rgb(15, 15, 15);
        padding: 0.1rem 0.4rem;
        border: 0.1rem solid #000000;
    }
    &-mini 
    {
        font-size: 0.9rem;
        padding: 0.2rem 0.4rem;
        margin-bottom: 0.2rem;
        display: inline-block;
    }
}
.categories
{
    margin-bottom: 0.8rem;
    h4 
    {
        margin-top: 0.3rem;
        margin-bottom: 0.3rem;
    }
}