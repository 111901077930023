.balance
{
    &__p
    {
        font-size: .9rem;
        font-family: 'OpenSans-SemiBold';
    }
    &__span
    {
        font-family: 'OpenSans-SemiBold';
        font-size: 3rem;
    }
    &-topup
    {
        background: #dcdcdc;
        border-radius: 0.3rem;
        box-shadow: 3px 7px 7px 0px rgba(51, 51, 51, 0.3);
        @media screen and (min-width: 768px) {
            margin-top: -2rem;
        }
        &__h2
        {
            font-size: 1.2rem!important;
            margin-bottom: 0.8rem;
            font-family: 'OpenSans-SemiBold'!important;
            text-align: center;
        }
    }
    &-radio
    {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        list-style: none;
        width: 100%;
        &__input
        {
            //margin-right: 0.7rem;
            opacity: 0;
            width: 0;
        }
        &__submit
        {
            margin: 1rem auto;
            display: flex;
            justify-content: center;
        }
    }
    &-pay
    {
        flex: 1 1 30%;
        background: #fff;
        border: 1px solid #000;
        padding: 0.3rem;
        position: relative;
        margin: 0.2rem;
        display: flex;
        height: 2rem;
        justify-content: center;
        align-items: center;
        width: 30%;

        img
        {
            max-width: 100%;
            max-height: 100%;

        }
        span
        {
            display: block;
            position: absolute;
            background: rgb(65, 65, 175);
            color: #fff;
            font-size: 0.7rem;
            padding: 0.2rem;
            top:0;
            right: 0;
            transition: 0.2s all ease;
        }
        &:hover
        {
            border: 1px solid rgb(65, 65, 175);
            transform: scale(1.05);
            transition: 0.2s all ease;
            cursor: pointer;
            // background: rgb(65, 65, 175);
        }
        &.active
        {
            border: 2px solid rgb(32, 32, 134);
            transform: scale(1.1);
        }
    }
    &-history
    {
        margin-top: 1.5rem;
        .fas
        {
            font-size: 1rem;
        }
        .fa-caret-up
        {
            color: rgb(4, 104, 4);
        }
        .fa-caret-down
        {
            color: rgb(175, 0, 0);
        }
        .fa-undo-alt
        {
            color: #4778ce;
        }
    }
    &-tab
    {
        &__date
        {
          font-family: 'OpenSans-SemiBold';
          color: #111;
          text-align: center;
          display: block;
        }
        &__time
        {
            display: block;
            text-align: center;
            font-size: 0.8rem;
        }
        &__in
        {
            color: rgb(4, 104, 4);
            font-family: 'OpenSans-SemiBold';
        }
        &__out
        {
            color: rgb(175, 0, 0);
            font-family: 'OpenSans-SemiBold';
        }
        &__comment
        {
            font-family: 'OpenSans-Bold';
        }
    }
    &-send
    {
        @include button($blue, $bluehover);
		border: none;
        margin-top: 2rem;
    }
    &-users
    {
        width: 90%;
        box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.3);
        border-radius: 3px;
        margin-bottom: 1.5rem;
        transition: all .3s ease;
        max-height: 20rem;
        overflow-y: auto;
        background: var(--ebg-color);
        &.codrivers
        {
            bottom: 4rem!important;
            top: initial!important;
            max-height: 14rem;
            z-index: 4;
        }
        &.absolute
        {
            position: absolute;
            top: -0.9rem;
        }
        &::-webkit-scrollbar {
            width: 8px;               /* width of the entire scrollbar */
          }
        &::-webkit-scrollbar-thumb {
            background-color: rgb(128, 128, 128);
            border-radius: 5px;   /* color of the scroll thumb */
              /* creates padding around scroll thumb */
          }
    }

    &-user
    {
        padding: 0.2rem 0.5rem;
        display: flex;
        border-bottom: 2px solid var(--eccc);
        align-items: center;
        cursor: pointer;
        width: 100%;
        &__avatar
        {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            overflow: hidden;
            margin-left: 1rem;
            margin-right: 1.5rem;
            img
            {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
        p
        {
            font-family: 'OpenSans-SemiBold';
        }
        &:hover
        {
            background: rgb(241, 241, 241);
        }
    }
    &-selected-user
    {
        padding: 0.2rem 0.5rem;
        display: flex;
        box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.3);
        border-radius: 3px;
        align-items: center;
        width: 100%;
        margin-bottom: 1.5rem;
        position: relative;
        &__avatar
        {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            overflow: hidden;
            margin-left: 1rem;
            margin-right: 1.5rem;
            img
            {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
        p
        {
            font-family: 'OpenSans-SemiBold';
        }
        &__close
        {
            position: absolute;
            right: 1rem;
            top: 1rem;
            position: absolute;
            right: 1rem;
            bottom: 50%;
            transform: translateY(50%);
            cursor: pointer;
        }
    }

}
