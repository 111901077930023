.vdes
{
    background: #000;
    border-radius: 1rem;
    color: #fff;
    
    display: flex;
    font-family: 'OpenSans-SemiBold';
    font-size: 1.5rem;
    padding: 1rem;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.5);
    &__logo 
    {
        width: 160px;
    }
    img 
    {
        max-height: 100%;
        max-width: 100%;
    }
    a 
    {
        background: #c80000;
        border-radius: 0.3rem;
        border: 3px solid #c80000;
        color: #fff;
        padding: 0.3rem 1.5rem;
        transition: .3s all ease;
        &:hover
        {
            background: none;
            transition: .3s all ease;
        }
    }
    &-text
    {
        display: flex;
        max-height: 100%;
        justify-content: center;
        align-items: center;
        // flex-wrap: no-wrap;
        div 
        {
            flex-basis: 80px;
        }
        img 
        {
            width: auto;
            
        }
    }
}