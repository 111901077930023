#pagination
{
    margin: 20px auto;
    .pagination
    {

        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;
        justify-content: center;
        .page-item
        {
            border: solid 1px rgb(209, 209, 209);
            margin-left: -1px;
            padding: 7px 10px;
            a
            {
                text-decoration: none;
                color: $blue;

            }
            &:first-child
            {
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }
            &:last-child
            {
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }
            &:hover:not(.disabled):not(.active)
            {
                background: var(--emain-white-hover);
            }
            &.active
            {
                color: #fff;
                background: var(--eblue);
            }
        }
    }
}
