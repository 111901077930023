.premium
{
    &-img
    {
        max-width: 100%;
        border-radius: 1rem;
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25);
    }
    &-desc
    {
        font-family: 'OpenSans-SemiBold';
        font-size: 1.2rem;
        line-height: 2rem;
    }
    .row
    {
        align-items: center;
    }
    &-button
    {
        display: flex;
        justify-content: center;
        &__link
        {
            @include button($blue, $bluehover);
		    border: none;
        }
    }
    &-price
    {
        display: flex;
        flex-direction: column;
        align-self: center;
        &__item
        {
            font-family: 'OpenSans-Bold';
            font-size: 2.5rem;
            text-align: center;
            color: #252525;
            margin-bottom: 0;
        }
        &__post
        {
            margin-top: 0;
            font-size: 1.2rem;
            text-align: center;
            color: #252525;
            font-family: 'OpenSans-SemiBold';
        }
    }
    &-tarif
    {
        padding: 1px 2rem 3rem;
        margin-bottom: 2rem;
        // input
        // {
        //     display: block;
        // }
        background: var(--einfo-label);
        border-radius: 18px;
        box-shadow: var(--elabel-shadow);
        &__input
        {
            margin:  1rem auto 1rem;
            display: flex;
            justify-content: center;
        }
        &.form
        {
            display: block!important;

        }
    }

    input
    {
        margin: auto;

    }
    h3
    {
        font-size: 1.6rem;
        // color: #252525;
    }
    input[type="number"]::-webkit-outer-spin-button,
            input[type="number"]::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
            input[type="number"] {
                -moz-appearance: textfield;
            }
    &.form-item
    {
        &.valid
        {
            color: green;
            label
            {
                color: green;
            }
        }
    }


    &_team
    {
        width: 100%;
        &-tarif
        {
            background: rgba(214, 209, 209, 0.1);
            border-radius: 1rem;
            padding: 1.5rem 2rem 2.5rem;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.16);
            margin-bottom: 1.5rem;
            -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
            border-top: 2px solid #fafafa;
            border-left: 2px solid #f5f5f5;
            transition: transform .2s ease-in;
            &.denied
            {
                opacity: 0.5;
            }
            &:hover
            {
                transform: scale(1.04);
                transition: transform .2s ease-in;
                cursor: pointer;
            }
            &.selected
            {
                background: rgba(5, 196, 116, 0.16);
                border-top: 2px solid #c1e9cc;
                border-left: 2px solid #b8ebc6;
            }
        }
        &-settings
        {
            background: rgba(214, 209, 209, 0.1);
            border-radius: 1rem;
            padding: 1.5rem 2rem 0.5rem;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.16);
            margin-bottom: 1.5rem;
            -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
            border-top: 2px solid #fafafa;
            border-left: 2px solid #f5f5f5;
            transition: transform .2s ease-in;
            display: block;
            &.denied
            {
                opacity: 0.5;
            }
            &:hover
            {
                transform: scale(1.04);
                transition: transform .2s ease-in;
                cursor: pointer;
            }
            &.selected
            {
                background: rgba(5, 196, 116, 0.16);
                border-top: 2px solid #c1e9cc;
                border-left: 2px solid #b8ebc6;
            }
            &__icon
            {
                font-size: 3rem;
                text-align: center;
                margin: auto;
                display: block!important;
                color: #868686;
            }
            &__h4
            {
                text-align: center;
            }
        }
        &-modal
        {
            position: fixed;
            bottom: -1000px;
            transition: all .3s ease;
            background: #FF2121;
            border-radius: 0.3rem;
            font-family: 'OpenSans-SemiBold';
            font-size: 1.2rem;
            color: #fafafa;
            padding: 0.7rem 2rem;
            box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.6);
            &.show
            {
                bottom:100px;
                transition: all .3s ease;
            }

        }
        &-modal-window
        {
            position: fixed;
            background: rgba(0,0,0,0.6);
            width: 100%;
            height: 100%;
            display: none;
            z-index: 100;
            top: 0;
            justify-content: center;
            align-items: center;
            backdrop-filter: blur(1.5px);
            &-container
            {
                border-radius: 1rem;
                width: 18rem;
                height: auto;
                padding: 1rem 1rem 2rem;
                background: rgba(255, 255, 255, 0.6);
                backdrop-filter: blur(5px);
                box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25);
                h2
                {
                    text-align: center;
                }
            }
            &-button
            {
                display: flex;
                justify-content: center;
                a
                {
                    margin: 0 0.5rem;
                }
            }
            &.active
            {
                display: flex;
            }
        }
        .drivers_list
        {
            li
            {margin-bottom: 1rem;}
            margin-bottom: 1rem;

        }
        &-link
        {
            margin: 0 1rem!important;
            background: var(--ebg-color);
            &-open
            {
                color: #4d4d4d;
                &:hover
                {
                    color: #252525;
                }
            }
        }
    }
}
