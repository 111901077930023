.protest
{
    background: var(--ebg-gradient);
    margin-top: 0px;
    padding-top: 120px;
    padding-bottom: 80px;
    z-index: -1;
    color: var(--eblack);
    .content
    {
        padding: 40px;
        background: var(--ebg-color);
        box-shadow: 0px -5px 29px -5px rgba(0, 0, 0, 0.35);
    }
    &-event
    {
        margin-bottom: 1.5rem;
        &__item
        {
            margin-bottom: 1.2rem;
        }
    }
    &-names
    {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2rem;
        &__bold
        {
            font-weight: 600;
        }
    }
    &-youtube
    {
        iframe
        {
            max-width: 100%;
        }
    }
    &-item
    {
        border-radius: 4px;
        padding:30px 40px;
        margin-top: 2rem;
        margin-bottom: 3rem;
        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
        background: var(--eprotest-bg);
    }
    &-buttons
    {
        flex-direction: row-reverse;
    }
    &-link
    {
        position: absolute;
        top: 0;
        right: 0;
    }
    &-modal
    {
        position: fixed;
        bottom: -1000px;
        transition: all .3s ease;
        background: rgba(26, 26, 26, 0.9);
        border-radius: 0.3rem;
        font-family: 'OpenSans-SemiBold';
        font-size: 1.2rem;
        color: #fafafa;
        padding: 0.7rem 2rem;
        box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.6);
        &.show
        {
            bottom:100px;
            transition: all .3s ease;
        }
    }
    &-copy
    {
        cursor: pointer;
    }
}
@media screen and (max-width: 568px)
{
    .protest
    {
        &-names
        {
            flex-direction: column;
        }
    }
}
