.skins
{

    background: var(--ebg-gradient);
    margin-top: 0px;
    padding-top: 120px;
    padding-bottom: 80px;
    z-index: -1;
    fieldset
    {
        // border: grey;
        border-radius: 0.5rem;
        border: solid 2px var(--eblack);
        margin-top: 0.5rem;
        legend
        {
            color: var(--eblack);
        }
    }
    .btn-green
    {
        margin-top: 1rem;
    }
    .content
    {
        padding: 40px;
        background: var(--ebg-color);
        box-shadow: 0px -5px 29px -5px rgba(0, 0, 0, 0.35);
    }
    &-item
    {
        box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.3);
        border-radius: 3px;
        margin-bottom: 1.5rem;
        transition: all .3s ease;
        &-img
        {
            position: relative;
            overflow: hidden;
            &.passed
            {
                img
                {
                    -webkit-filter: grayscale(70%) blur(1px);
                    filter: grayscale(70%) blur(1px);
                }
            }
            img
            {
                object-fit: cover;
                width: 100%;
                height: 15rem;
                transition: .3s ease;
            }
        }
        &-h3
        {
            position: absolute;
            bottom: 0;
            left: 1rem;
            color: #fafafa;
            font-weight: 600;
            font-size: 1.4rem;
            text-shadow: 2px 2px 2px #000;
        }
        h4
        {
            margin-bottom: 0.5rem;
        }
        h5
        {
            margin-top: 0;
            margin-bottom: 1rem;
            color: $blue;

        }
        &:hover
        {
                    transform: scale(1.05);
                    transition: all .3s ease;
        }
        &__completed
        {
            position: absolute;
            top: 0;
            left:0%;

            -webkit-filter: grayscale(0%) blur(0.7px)!important;
            filter: grayscale(0%) blur(0.7px)!important;
        }
        &__serieslogo
        {
            position: absolute;
            top: 2rem;
            right: -4rem;
            max-width: 15rem !important;
            transform: rotateZ(45deg);
            height: auto !important;
            padding: 0 4rem;
            background: #4778ce;
        }
        &__sa
        {
            color: #ffffff;
            font-weight: 600;
            font-size: 1rem;
            text-transform: uppercase;
            background: #930006;
            display: block;
            padding: 0.5rem 0.5rem 0.5rem 1rem;
            margin-bottom: 1rem;
            width: -webkit-fit-content;
            width: -moz-fit-content;
            width: fit-content;
            position: absolute;
            right: 0;
            bottom: 0.5rem;
            &::before
            {
                content:"\A";
                border-style: solid;
                border-width: 2rem 0rem 0rem 1rem;
                border-color: #930006 transparent transparent transparent;
                position: absolute;
                left: -1rem;
                top: 0;
            }
        }
        &-content
        {
            padding: 0 1rem 1rem;
            position: relative;
            &__flag
            {
                width: 1.2rem;
                height: 1.2rem;
                border-radius: 40%;
                margin-left: 1.3rem;
                -webkit-box-shadow: 1px 1px 4px 0px rgba(0,0,0,0.75);
                -moz-box-shadow: 1px 1px 4px 0px rgba(0,0,0,0.75);
                box-shadow: 1px 1px 4px 0px rgba(0,0,0,0.75);
            }
            &-drivers
            {
                position: absolute;
                top: -10px;
                right: 1rem;
                font-size: 2rem;
                color: $blue;
            }
        }
    }
    &-empty
    {
        border-radius: 15px 15px 15px 15px;
        -moz-border-radius: 15px 15px 15px 15px;
        -webkit-border-radius: 15px 15px 15px 15px;
        border: 4px dashed #cfcfcf;
        margin-bottom: 1.5rem;
        height: calc(100% - 1.5rem);

        &__link
        {
            font-family: 'OpenSans-ExtraBold';
            font-size: 8rem;
            color: #cfcfcf;
            text-decoration: none;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &:hover
        {
            background: var(--ealmost-white);
        }
    }
    &-links
    {

        &-row
        {
            display: flex;
            align-items: center;
        }
        &__p
        {
            font-family: 'OpenSans-SemiBold';
            font-size: 1.2rem;
            color: var(--eblack);
            a
            {
                color: #4878cd;
                text-decoration: none;
                &:hover
                {
                    text-decoration: underline;
                }
            }
        }
        &__img
        {
            max-width: 100%;
            border-radius: 1rem;
            box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25);
        }
        &__btn
        {
            @include button($blue, $bluehover);
            border: none;
            text-align: center;
        }
    }
    &-manual
    {

        height: 0;
        overflow: hidden;
        transition: all .3s ease-out;
        z-index: -1;
        &.visible
        {
            height: auto;
            z-index: 1;

            transition: all .3s ease-out;
        }
        p
        {
            font-family: 'OpenSans-Regular';
        }
        &__bold
        {
            font-family: 'OpenSans-SemiBold';
            color: #4778ce;
        }
        &__code
        {
            background: #000;
            padding: 0.4rem;
            font-family: 'OpenSans-SemiBold';
            margin: 1rem;
            border-radius: 3px;
            display: block;
            color: #4778ce;
            .red
            {
                color: #933600;
            }
        }
    }
    &-video
    {
        iframe
        {
            width: 100%;
            height: 350px;
        }
    }
    &-howto
    {
        &:hover
        {
            cursor: pointer;
        }
    }
}
