.create-event
{
    padding-bottom: 50px;
    .presets
    {
        display: flex;
        align-items: center;
        h3, a
        {
            margin-left: 10px;
        }
    }
    .form
    {
        position: relative;
        flex-wrap: wrap;
        &-item
        {
            flex: 0 0 300px;
            &__input
            {
                font-size: 18px;
            }
            &.active
            {
                font-size: 10px;
                label
                {
                    font-size: 12px;
                    
                }
            }
            label
            {
                font-size: 18px;
                
            }
        }
        
    }
    .add
    {
        position: absolute;
        top: 10px;
    }
    .session
    {
        flex-basis: 280px;
        width: 280px;
        margin-left: 20px;
    }
    .submit
    {
        width: 50%;
        min-width: 280px;
        text-align: center;
        display: block;
        margin: auto;
        font-size: 1.5em;
    }
    &__btn 
    {
        margin-bottom: 20px;
    }
}