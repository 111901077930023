.nav
{
	min-height: 100vh;
	width: 340px;
	max-width: 100%;
	position: fixed;
	top: 0;
	left: -600px;
	background: var(--enav-bg);
	transition: all .3s ease-out;
	z-index: 10;
	@media screen and (max-device-width: 720px) {
		display: none;
	}
	@media screen and (max-device-height: 568px) {
		display: none;
	}
	&-header
	{
		height: 70px;
		border-bottom: 2px solid #151515;

		-webkit-box-shadow: -20px 5px 17px 0px rgba(0,0,0,0.5);
		-moz-box-shadow: -20px 5px 17px 0px rgba(0,0,0,0.5);
		box-shadow: -20px 5px 17px 0px rgba(0,0,0,0.5);
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		align-content: center;
		align-items: center;

	}

	&-button
	{
		font-size: 35px;
		margin: 30px;
		a, a:hover
		{
			text-decoration: none;
			color: #e4e4e4;
		}
	}
	&-logo
	{
		width: 185px;
		height: auto;
		max-width: 100%;
		margin: 10px;
	}
	&.active
	{
		left: 0;
		transition: all .3s ease-in-out;
	}
	&-body
	{
		margin-top: 1.7rem;
		ul
		{
			list-style: none;
			padding: 0;
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
	}
	&-item
	{
		//margin-bottom: 1.2rem;
		position: relative;
		display: block;
		width: 340px;
		max-width: 100%;
		padding: 0.6rem 2rem;
		&:hover
		{
			background: #545454;
		}
		img
		{
			max-width: 100%;
		}
		i
		{
			margin-right: 1rem;
			width: 2rem;
			font-size: 1.4rem;
			position: relative;
   			bottom: -0.1rem;
		}
		// &:after
		// {
		// 	content: '';
		// 	position: absolute;
		// 	bottom: -10px;
		// 	height: 0px;
		// 	width: 190px;
		// 	border-bottom: 1px solid #e4e4e4;
		// 	right: 0;
		// 	left: 0;
		// 	margin-left: 0;
		// 	margin-right: 0;
		// }
		&:last-child:after
		{
			border: none;
		}
		&__link
		{
			color: #e4e4e4;
			text-decoration: none;

			text-align: left;
			font-size: 1rem;
			font-family: 'OpenSans-SemiBold';
			transition: all .3s ease-in;
			&:hover
			{
				color: #bebebe;
				transition: all .3s ease-out;
			}
		}
	}
	&-icons
	{
		margin: 1.0rem;
		display: flex;
		color: #fefefe;
		font-size: 2rem;
		justify-content: space-around;
		a
		{
			color: #e4e4e4;
			transition: all .2s ease;
			margin: 0 0.3rem;
		}
		a:hover
		{
			transform: scale(1.1);
			transition: all .2s ease;
		}
		a:hover .fa-discord
 		{
			color: #5865F2;
		}
		a:hover .fa-youtube
 		{
			color: #FF0000;
		}
		a:hover .fa-instagram
 		{
			color: #833AB4;
		}
		a:hover .fa-vk
 		{
			color: rgb(89,125,163);
		}
		a:hover .fa-patreon
 		{
			color: #ff424d;
		}
		a:hover .fa-telegram
 		{
			color: #229ED9;
		}

	}
	&-user
	{
		margin-left: 2rem;
		@media screen and (max-device-width: 568px) {
			margin-left: 1rem;
		}
		&__icon
		{
			width: 2rem;
			height: 2rem;
			object-fit: cover;
			border-radius: 50%;
		}
		.content
		{
			max-height: 80vh;
			display: none;
			overflow-y: auto;
			position: absolute;
			right: 6rem;
			top: 1rem;
			//margin-top: -2rem;
			width: 15rem;
			font-size: 1rem;
			background: var(--ebg-color);
			-webkit-box-shadow: 1px 0px 5px 0px rgba(0,0,0,0.5);
			-moz-box-shadow: 1px 0px 5px 0px rgba(0,0,0,0.5);
			box-shadow: 1px 0px 5px 0px rgba(0,0,0,0.5);
			&-header
			{
				background: var(--ealmost-white);
				display: flex;
				justify-content: space-around;
				align-items: center;
				padding-top: 0.7rem;
				padding-bottom: 1rem;
				&-meta
				{
                    color: var(--eblack);
					margin-right: 1rem;
				}
				h4
				{
					margin-top: 0.6rem;
					margin-bottom: 0;
				}
				span
				{
					font-family: 'OpenSans-SemiBold';
					font-size: 0.7rem;
				}
				&-avatar
				{
					display: flex;
					align-items:baseline;
					&__img
					{
						width: 3rem;
						height: 3rem;
						object-fit: cover;
						border-radius: 50%;
						padding: 0.2rem;
					}
				}
			}
			&.active
			{
				display: block;
			}
			&-main
			{
				border-bottom: 2px solid var(--ealmost-white);
				&__link
				{
					color: var(--eh-color);
					font-family: 'OpenSans-SemiBold';
					display: block;
					padding: 0.5rem 2rem;
					font-size: 0.8rem;
					text-decoration: none;
					max-width: 100%;
					transition: 0.3s all ease;
					i
					{
						margin-right: 0.5rem;
						font-size: 1.2rem;
					}
					&:hover
					{
						background: var(--eccc);
						transition: 0.3s all ease;
					}
				}
			}
			&-footer
			{
				&__link
				{
					color: var(--eh-color);
					font-family: 'OpenSans-SemiBold';
					display: block;
					padding: 0.5rem 2rem;
					font-size: 0.8rem;
					text-decoration: none;
					width: 100%;
					transition: 0.3s all ease;
					i
					{
						margin-right: 0.5rem;
						font-size: 1.2rem;
					}
					&:hover
					{
						background: var(--eccc);
						transition: 0.3s all ease;
					}
				}
			}
		}
	}
}
