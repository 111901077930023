.server
{
    &-item
    {
        box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.3);
        border-radius: 3px;
        margin-bottom: 1.5rem;
        transition: all .3s ease;
        padding-top: 3rem;
        position: relative;
        overflow: hidden;
        h3
        {
            margin-top: -1.5rem;
            margin-bottom: 0.5rem;
            // margin-left: 0.5rem;
        }
        h5
        {
            margin-bottom: 0;
        }
        &.working
        {
            background: rgba(0, 110, 255, 0.102);
        }
        &-bg
        {

            position: absolute;
            top:-1rem;
            right: -1rem;
            .fas
            {
                font-size: 8rem;
                opacity: 0.05;
            }
        }
        &-img
        {
            position: relative;
            overflow: hidden;
            &.passed
            {
                img
                {
                    -webkit-filter: grayscale(70%) blur(1px);
                    filter: grayscale(70%) blur(1px);
                }
            }
            img
            {
                object-fit: cover;
                width: 100%;
                height: 15rem;
                transition: .3s ease;
            }
        }
        &-h3
        {
            position: absolute;
            bottom: 0;
            left: 1rem;
            color: #fafafa;
            font-weight: 600;
            font-size: 1.4rem;
            text-shadow: 2px 2px 2px #000;
        } 
        h4
        {
            margin-bottom: 0.5rem;
        }
        h5
        {
            margin-top: 0;
            margin-bottom: 1rem;
            color: $blue;

        }
        &:hover
        {
                    transform: scale(1.05);
                    transition: all .3s ease;
        }
        &__completed
        {
            position: absolute;
            top: 0;
            left:0%;

            -webkit-filter: grayscale(0%) blur(0.7px)!important;
            filter: grayscale(0%) blur(0.7px)!important;
        }
        &__serieslogo
        {
            position: absolute;
            top: 2rem;
            right: -4rem;
            max-width: 15rem !important;
            transform: rotateZ(45deg);
            height: auto !important;
            padding: 0 4rem;
            background: #4778ce;
        }
        &__sa
        {
            color: #ffffff;
            font-weight: 600;
            font-size: 1rem;
            text-transform: uppercase;
            background: #930006;
            display: block;
            padding: 0.5rem 0.5rem 0.5rem 1rem;
            margin-bottom: 1rem;
            width: -webkit-fit-content;
            width: -moz-fit-content;
            width: fit-content;
            position: absolute;
            right: 0;
            bottom: 0.5rem;
            &::before
            {
                content:"\A";
                border-style: solid;
                border-width: 2rem 0rem 0rem 1rem;
                border-color: #930006 transparent transparent transparent;
                position: absolute;
                left: -1rem;
                top: 0;
            }
        }
        &-content
        {
            padding: 0 1rem 1rem;
            position: relative;
            .btn
            {
                min-height: auto;
            }
            &__flag
            {
                width: 1.2rem;
                height: 1.2rem;
                border-radius: 40%;
                margin-left: 1.3rem;
                -webkit-box-shadow: 1px 1px 4px 0px rgba(0,0,0,0.75);
                -moz-box-shadow: 1px 1px 4px 0px rgba(0,0,0,0.75);
                box-shadow: 1px 1px 4px 0px rgba(0,0,0,0.75);
            }
            &-drivers
            {
                position: absolute;
                top: -10px;
                right: 1rem;
                font-size: 2rem;
                color: $blue;
            }
        }
        &-form
        {
            margin-right: 0.5rem;
        }
        &-event
        {
            &:hover
            {
                cursor: pointer;
            }
            &.active
            {
                background: rgba(0, 110, 255, 0.102);
            }
        }
    }
}
