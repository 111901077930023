.bl
{
    box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.30);
    margin-top: 1rem;
    &-item
    {
        display: grid;
        grid-template-columns: 70px auto 140px;
        padding: 0.8rem 1.5rem;
        color: var(--eblack);
        border-bottom: 2px solid var(--eccc);
        &.users
        {
            grid-template-columns: 70px auto 200px 40px;

        }
        &:last-child
        {
            border: none;
        }
        &__img
        {
            max-width: 50px;
            flex: 1 1 100px;
            img
            {
                max-width: 100%;
                object-fit: cover;
            }

        }
        &__name
        {
            flex: 8 1 100px;
            padding: 0rem 0rem 0rem 1.5rem;
            display: flex;
            align-items: center;
            font-size: 1.3rem;
            font-weight: 600;

        }
        &__btns
        {
            flex: 1 1 100px;
            justify-self: end;
            display: grid;
            align-items: center;
            gap: 5px;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            a
            {
                font-size: 2rem;
                margin-right: 0.3rem;
                color: #868686;
            }
        }
        &__date
        {
            display: flex;
            align-items: center;
            font-size: 1.2rem;
            font-weight: 600;
        }
    }
}
