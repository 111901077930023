.pteam
{
    width: 100%;
    min-height: 100vh;
    &-nav
    {
        @media screen and (max-width:568px) {
            z-index: 1000;
            position: fixed;
            background: rgba(255, 255, 255, 0.55);
            -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
            padding: 1rem 1.3rem;
            border-top-left-radius: 1rem;
            border-top-right-radius: 1rem;
            border-bottom-left-radius: 0;
            -webkit-box-shadow: -4px -4px 11px 0px rgba(34, 60, 80, 0.16);
            -moz-box-shadow: -4px -4px 11px 0px rgba(34, 60, 80, 0.16);
            box-shadow: -4px -4px 11px 0px rgba(34, 60, 80, 0.16);
            bottom: 0;
            /* height: 160px; */
            left: calc(50% - 80px);
            bottom: 0;
            width: 160px;
            margin: auto;
            display: flex;
            right: auto;
            height: auto;
            top: auto;
            justify-content: space-around;
        }
        z-index: 1000;
        position: fixed;


        background: rgba(255, 255, 255, 0.55);
        backdrop-filter: blur(5px);
        padding: 1rem 1.3rem;
        border-top-left-radius: 1rem;
        border-bottom-left-radius: 1rem;
        -webkit-box-shadow: -4px -4px 11px 0px rgba(34, 60, 80, 0.16);
        -moz-box-shadow: -4px -4px 11px 0px rgba(34, 60, 80, 0.16);
        box-shadow: -4px -4px 11px 0px rgba(34, 60, 80, 0.16);
        border-left: 1px solid rgba(243, 243, 243, 0.7);
        border-top: 2px solid rgba(254, 254, 254, 0.5);
        right: 0;
        height: 160px;
        top: calc(50% - 80px);
        bottom: 50%;
        &__link
        {
            display: block;
            font-size: 1.8rem;
            margin-bottom: 0.5rem;
            color: #dcdcdc;
            transition: all 0.3s ease-in-out;
            &:last-child
            {
                margin-bottom: 0;
            }
            &:hover
            {
                color: #9c9c9c;
                transform: scale(1.1);
                transition: all 0.3s ease-in-out;
            }
        }

    }
    &-logo
    {
        height: 105px;
        width: 250px;
        padding: 2rem;
        &__img
        {
            max-width: 100%;
            max-height: 100%
        }
    }
    &-content
    {
        background: rgba(255,255,255,0.7);
        backdrop-filter: blur(5px);
        padding: 2rem 3rem;
        border-top-left-radius: 2rem;
        border-top-right-radius: 2rem;
        min-height: calc(100vh - 105px);
        -webkit-box-shadow: -4px -4px 11px 0px rgba(34, 60, 80, 0.16);
        -moz-box-shadow: -4px -4px 11px 0px rgba(34, 60, 80, 0.16);
        box-shadow: -4px -4px 11px 0px rgba(34, 60, 80, 0.16);
        border-left: 1px solid rgba(243, 243, 243, 0.7);
        border-top: 2px solid rgba(254, 254, 254, 0.5);
        @media screen and (max-width: 767px) {
            margin-top: 4rem;
            min-height: calc(100vh - 105px - 4rem);
        }
        &__logo
        {
            max-width: 250px;
            height: 120px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: auto;
            img
            {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        h1
        {
            text-align: center;
            font-family: 'OpenSans-ExtraBold';
        }
        &__description
        {
            color: #383838;
            font-size: 1.1rem;
            p
            {
                text-align: justify;
            }
        }
    }
    &-links
	{
        position: relative;
        bottom: 0;
		display: flex;
		justify-content: space-between;
		font-size: 35px;
		// width: 300px;
		max-width: 100%;
		margin: 2rem auto 0;
		color: grey;
		transform: scale(1.0);
        transition: .3s all ease;
        // margin-bottom: 2rem;
		a
		{

			&.team-site
			{
				color: rgb(0, 102, 255);
			}
			&.team-youtube
			{
				color: rgb(214, 0, 0);
			}
			&.team-twitch
			{
				color: #9147ff;
			}
			&.team-steam
			{
				color: #000;
			}
			&.team-vk
			{
				color: #4a76a8;
            }
			&.team-tg
			{
				color: #269bcd;
            }
			&.team-email
			{
				color: #000;
            }

			&:hover
			{
				transition: .3s all ease;
				transform: scale(1.1);
			}
		}
    }
    &-drivers
    {
        position: relative;
        overflow: hidden;
        padding-bottom: 4rem;
        background: rgba(222,222,222,0.3);
        &__bg
        {

            font-size: 15rem;
            position: absolute;
            z-index: -1;
            top: 1rem;
            min-width: 100vw;
            left: -6rem;
            font-family: 'OpenSans-ExtraBold';
            color: #707070;
            opacity: 0.08;
            // -webkit-touch-callout: none; /* iOS Safari */
            // -webkit-user-select: none;   /* Chrome/Safari/Opera */
            // -khtml-user-select: none;    /* Konqueror */
            // -moz-user-select: none;      /* Firefox */
            // -ms-user-select: none;       /* Internet Explorer/Edge */
            // user-select: none;
        }
        &__h2
        {
            font-size: 3rem;
            color: #383838;
            font-family: 'OpenSans-ExtraBold';
            margin-top: 5rem;
        }
        &-content
        {
            display: flex;
            flex-wrap: wrap;
        }
        // &-link
        // {
        //     text-decoration: none;
        //     &:hover
        //     {
        //         text-decoration: none;
        //         transform: scale(1.05);
        //         transition: 0.2s all cubic-bezier(0.075, 0.82, 0.165, 1);
        //     }
        // }
        &__profile-link
        {
            @include button($blue, $bluehover);
            text-align: center;
		    border: none;
            margin: 3px auto 1rem;

        }
        &-item
        {
            flex: 0 0 33.333333%;
            max-width: 33.333333%;
            padding-right: 2rem;
            padding-left: 2rem;
            @media screen and (max-width: 767px) {
                position: relative;
                width: 100%;
                padding-right: 15px;
                padding-left: 15px;
                flex: 0 0 100%;
                max-width: 100%;
            }
            &-img
            {
                position: relative;
                overflow: hidden;
                img
                {
                    object-fit: cover;
                    width: 100%;
                    height: 15rem;
                    transition: .3s ease;
                }
            }
            &-name
            {
                display: flex;
                justify-content: center;
                align-items: baseline;
                width: 100%;
                margin: auto;
                h2
                {
                    margin-right: 1rem;
                    font-size: 1.1rem;
                }
                .category
                {
                    font-size: 1.0rem;
                }
            }
            &-confirmed
            {
                text-align: center;
                background: #00a331;
                color: #fff;
                margin: 0rem auto 1.0rem;
                width: 200px;
                padding: 0.4rem;
                border-radius: 0.3rem;
            }
            &-not-confirmed
            {
                text-align: center;
                background: #74000a;
                color: #fff;
                margin: 0rem auto 1.0em;
                width: 200px;
                padding: 0.4rem;
                border-radius: 0.3rem;
            }
            &-delete
            {
                text-align: center;
                display: block;
                margin: auto;
                width: 100px;
            }
            &-content
            {
                padding: 0rem 1rem 1rem;
            }
        }
        &-card
        {
            overflow: hidden;
            border-radius: 1rem;
            -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.16);
            -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.16);
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.16);
            // background: #fff;
            background: var(--ebg-color);
            transition: .2s all ease-in;
            margin-bottom: 1.5rem;

            &:hover
            {
                transform: scale(1.03);
                transition: .2s all ease-in;
            }
        }
        &-links
        {
            display: flex;
            justify-content: space-around;
            font-size: 35px;
            width: 260px;
            max-width: 100%;
            margin: 10px auto;
            color: grey;
            transform: scale(1.0);
            transition: .3s all ease;
            margin-bottom: 1rem;
            a
            {
                &.pteam-drivers-youtube
                {
                    color: rgb(214, 0, 0);
                }
                &.pteam-drivers-twitch
                {
                    color: #9147ff;
                }
                &.pteam-drivers-steam
                {
                    color: #000;
                }
                &.pteam-drivers-vk
                {
                    color: #4a76a8;
                }
                &.pteam-drivers-tg
                {
                    color: #269bcd;
                }

                &:hover
                {
                    transition: .3s all ease;
                    transform: scale(1.1);
                }
            }
        }
    }
    &-stats
    {
        position: relative;
        overflow: hidden;
        padding-bottom: 4rem;
        background: rgb(252,252,252);
        background: linear-gradient(320deg, rgba(252,252,252,0.1) 16%, rgba(237,237,237,0.35) 33%, rgba(235,235,235,0.35) 48%, rgba(225, 240, 255, 0.3) 81%);
        &__bg
        {

            font-size: 15rem;
            position: absolute;
            z-index: -1;
            top: 1rem;
            min-width: 100vw;
            left: -6rem;
            font-family: 'OpenSans-ExtraBold';
            color: #707070;
            opacity: 0.08;
        }
        &__h2
        {
            font-size: 3rem;
            color: #383838;
            font-family: 'OpenSans-ExtraBold';
            margin-top: 5rem;
        }
        &-content
        {
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
        }
        &-item
        {
            background: rgba(214,209,209,0.1);
            border-radius: 1rem;
            padding: 1.5rem 2rem;
            -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.16);
            -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.16);
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.16);
            margin-bottom: 1.5rem;
            backdrop-filter: blur(5px);
            border-top: 2px solid #fafafa;
            border-left: 2px solid #f5f5f5;
            h3
            {
                margin-top: 0;
            }
            span
            {
                color: #a2a2a2;
                text-align: center;
                display: block;
                font-family: 'OpenSans-Bold';
                font-size: 1.3rem;
            }
        }
        &-table
        {
            background: rgba(214,209,209,0.1);
            border-radius: 1rem;
            padding: 1.5rem 2rem;
            -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.16);
            -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.16);
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.16);
            backdrop-filter: blur(5px);
            border-top: 2px solid #fafafa;
            border-left: 2px solid #f5f5f5;
            max-width: 100%;
            width: 700px;
            margin-bottom: 2rem;
            h3
            {
                font-size: 2rem;
            }
            table
            {
                width: 100%;
                font-weight: 600;
                td, th
                {
                    text-align: left;
                    padding: 15px 10px;
                    a.link
                    {
                        text-decoration: none;
                        color: #000;
                        &:hover
                        {
                            text-decoration:underline;
                        }
                    }
                }
                tr:not(:last-child)
                {
                    border-bottom: 2px solid #aaa9a9;
                }
            }
        }
    }
    &-sponsors
    {
        padding: 5rem 0 10rem;
        // background: rgb(124, 124, 124);
        position: relative;
        &__h2
        {
            font-size: 3rem;
            color: #fafafa;
            font-family: 'OpenSans-ExtraBold';
            // margin-top: 5rem;
        }
        &-bg
        {
            position: absolute;
            height: 100%;
            width: 100%;
            z-index: -1;
            top: 0;
            overflow: hidden;
            img
            {
                width: 100%;
                height: 100%;
                object-fit: cover;
                filter: blur(5px);
                transform: scale(1.05);

            }
        }
        &-content
        {
            .row
            {
                align-items: center;
            }
        }
        &-item
        {
            text-decoration: none;
            padding: 2rem;
            display: block;
            margin: auto;
            &__sponsor
            {
                color: #fafafa;
                font-size: 4rem;
                font-family: 'OpenSans-ExtraBold';
                text-decoration: none;
                text-align: center;
                &:hover
                {
                    color: #eee;
                }
            }
            img
            {
                max-width: calc(100% - 4rem);
                max-height: 400px;
            }
        }
        &-delete
        {
            margin-left: 3rem;
        }
    }
    &-footer
    {
        padding: 1rem;
        &-content
        {
            display: flex;
            justify-content: center;
            align-items: center;
            span
            {
                font-family: 'OpenSans-Bold';
                font-size: 1.2rem;
            }
        }
    }
    &-invite
    {
        padding: 40px;
        background: #ffffff;
        -webkit-box-shadow: 0px -5px 29px -5px rgba(0,0,0,0.35);
        -moz-box-shadow: 0px -5px 29px -5px rgba(0,0,0,0.35);
        box-shadow: 0px -5px 29px -5px rgba(0,0,0,0.35);
        width: 500px;
        max-width: 90%;
        margin: auto;
        &__logo
        {
            max-width: 100%;
            width: 450px;
            margin:auto;
        }
        &-buttons
        {
            display: flex;
            justify-content: center;
            align-items: center;
            a
            {
                margin: 5px;
            }
        }
    }
    &-exist
    {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        @media screen and (max-width: 568px) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }

        &-preview
        {
            position: relative;
            min-height: 25rem;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.35);
        }
        &-content
        {

            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin-right: 0;
            margin-left: 0;
            background: rgba(255,255,255,0.7);
            backdrop-filter: blur(5px);
            padding: 2rem 3rem;
            border-top-left-radius: 2rem;
            border-top-right-radius: 2rem;
            -webkit-box-shadow: -4px -4px 11px 0px rgba(34, 60, 80, 0.16);
            -moz-box-shadow: -4px -4px 11px 0px rgba(34, 60, 80, 0.16);
            box-shadow: -4px -4px 11px 0px rgba(34, 60, 80, 0.16);
            border-left: 1px solid rgba(243, 243, 243, 0.7);
            border-top: 2px solid rgba(254, 254, 254, 0.5);
            h3
            {
                text-align: center;
                margin-top: 0;
            }
        }
        &-description
        {
            padding: 2rem;
            h3
            {
                text-align: center;
            }
            p
            {
                text-align: center;
            }
            a
            {
                text-align: center;
                display: block;
                margin: auto;
                width: 5rem;
            }
        }
    }
    &-streams
    {
        background: rgb(28,28,28);
        background: linear-gradient(320deg, rgba(28,28,28,1) 37%, rgba(61,54,54,1) 100%);
        padding: 2rem;
        &__h2
        {
            color: #fafafa;
        }
        &-item
        {
            img
            {
                max-width: 100%;
                object-fit: cover;
            }
            &__name
            {
                font-family: 'OpenSans-SemiBold';
                font-size: 0.8rem;
                color: #fafafa;
                a
                {
                    color: #fafafa;
                    text-decoration: none;
                    &:hover
                    {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
