.stats
{
    h1
    {
        font-family: 'OpenSans-ExtraBold';
        font-size: 3rem;
        margin-bottom: 0;
    }
    h2
    {
        border-bottom: 1px solid rgb(207, 207, 207);
        padding-bottom: 1rem;
        font-size: 2rem;
    }
    &-left
    {
        position: relative;
    }
    &-val
    {
        position: absolute;
        width: calc(100% - 2rem);
        bottom: 0.2rem;
    }
    &-avatar
    {
        display: flex;
        justify-content: center;
        img
        {
            width: 100px;
            height: 100px;
            max-width: 100%;
            border-radius: 100%;
            border: 1px solid #eee;
            object-fit: cover;
        }
    }
    &-name
    {
        text-align: center;
    }
    &-item
    {
        padding-bottom: 2rem;
        h4
        {

            span.number
            {
                font-weight: 600;
                font-size: 1.8rem;
            }
            font-size: 0.8rem;
            text-align: center;
        }
        &-post
        {
            padding: 0.5rem;
            span
            {
                font-size: 0.8rem;
                font-style: italic;
            }
        }
    }
    &-list
    {
        list-style: none;
        font-size: 0.8rem;
        padding: 0;
        li
        {
            margin-bottom: 0.55rem;
            &:first-child
            {
                font-weight: 600;
            }
        }
        &__name
        {
            a
            {
                text-decoration: none;
                color: var(--eh-color);
                transition: .3s all ease;
                &:hover
                {
                    text-decoration: underline;
                    transition: .3s all ease;
                }
            }
        }
        &__value
        {
            text-align: center;
            display: inline-block;
            position: absolute;
            right: 1rem;
        }
    }
    &-track
    {
        &-item
        {

            h3
            {
                margin-top: 0.3rem;
                margin-bottom: 0.3rem;
            }
            margin-bottom: 2rem;
        }
        &-h3
        {
            color: #ffffff;
            // padding-top: 4rem;
            // padding-bottom: 2rem;
            // padding-left: 1.5rem;
            font-size: 1.8rem!important;
            border-bottom: none!important;
            text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
            &-races
            {
                color: #ffffff;
                text-shadow: 2px 2px 4px rgba(0, 0, 0, 1)
            }
            &-laps
            {
                color: #ffffff;
                text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
                text-align: right;
            }
        }
        &__link
        {
            font-size: 0.8rem;
            color: var(--eblack);
            font-weight: 600;
            position: relative;
            right: 0;
            top:0;
            display: block;
            text-align: right;
            &:hover
            {
                text-decoration: underline;

            }
        }
    }
}
