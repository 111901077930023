@font-face {
  font-family: 'OpenSans-SemiBoldItalic';
  src: url('../fonts/OpenSans-SemiBoldItalic.eot');
  src: url('../fonts/OpenSans-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
       url('../fonts/OpenSans-SemiBoldItalic.svg#OpenSans-SemiBoldItalic') format('svg'),
       url('../fonts/OpenSans-SemiBoldItalic.ttf') format('truetype'),
       url('../fonts/OpenSans-SemiBoldItalic.woff') format('woff'),
       url('../fonts/OpenSans-SemiBoldItalic.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans-SemiBold';
  src: url('../fonts/OpenSans-SemiBold.eot');
  src: url('../fonts/OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'),
       url('../fonts/OpenSans-SemiBold.svg#OpenSans-SemiBold') format('svg'),
       url('../fonts/OpenSans-SemiBold.ttf') format('truetype'),
       url('../fonts/OpenSans-SemiBold.woff') format('woff'),
       url('../fonts/OpenSans-SemiBold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans-Regular';
  src: url('../fonts/OpenSans-Regular.eot');
  src: url('../fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
       url('../fonts/OpenSans-Regular.svg#OpenSans-Regular') format('svg'),
       url('../fonts/OpenSans-Regular.ttf') format('truetype'),
       url('../fonts/OpenSans-Regular.woff') format('woff'),
       url('../fonts/OpenSans-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans-Light';
  src: url('../fonts/OpenSans-Light.eot');
  src: url('../fonts/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
       url('../fonts/OpenSans-Light.svg#OpenSans-Light') format('svg'),
       url('../fonts/OpenSans-Light.ttf') format('truetype'),
       url('../fonts/OpenSans-Light.woff') format('woff'),
       url('../fonts/OpenSans-Light.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans-ExtraBold';
  src: url('../fonts/OpenSans-ExtraBold.eot');
  src: url('../fonts/OpenSans-ExtraBold.eot?#iefix') format('embedded-opentype'),
       url('../fonts/OpenSans-ExtraBold.svg#OpenSans-ExtraBold') format('svg'),
       url('../fonts/OpenSans-ExtraBold.ttf') format('truetype'),
       url('../fonts/OpenSans-ExtraBold.woff') format('woff'),
       url('../fonts/OpenSans-ExtraBold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans-Bold';
  src: url('../fonts/OpenSans-Bold.eot');
  src: url('../fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
       url('../fonts/OpenSans-Bold.svg#OpenSans-Bold') format('svg'),
       url('../fonts/OpenSans-Bold.ttf') format('truetype'),
       url('../fonts/OpenSans-Bold.woff') format('woff'),
       url('../fonts/OpenSans-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

