
// Fonts
@import 'https://fonts.googleapis.com/css?family=Nunito';

// Variables
@import 'variables';

@import '../../../node_modules/normalizecss/normalize';
@import '../../../node_modules/bootstrap/dist/css/bootstrap-grid';
@import "font";
@import "font-awesome";

:root
{
    --ebg-color: #fff;
    --ealmost-white: #eee;
    --eh-color: #383838;
    --eblue: #4778ce;
    --ebluehover: #4778ce;
	--ehuge-shadow: 0px -5px 29px -5px rgba(0, 0, 0, 0.35);
    --ebg-gradient: linear-gradient(to bottom, rgba(71,120,206,1) 0%, rgba(71,120,206,1) 350px, rgba(0,78,92,1) 370px, rgba(255, 255, 255, 0) 371px, rgba(255, 255, 255, 0) 100%);
    --einfo-label: #dcdcdc;
    --ered: red;
    --egreen: green;
    --elabel-shadow: 3px 7px 7px 0px rgba(51,51,51,0.3);
    --esession-border: 2px solid #aaa9a9;
    --emain-white-hover: rgb(241, 241, 241);
    --eeblack: #000;
    --eblue: rgb(0, 30, 163);
    --edarkblue: rgb(0, 22, 119);
    --eccc: #ccc;
    --efavs: #f4f4f4;
    --efafafa: #fafafa;
    --ef0f0f0: #f0f0f0;
    --eprofile-shadow: rgba(51, 51, 51, 0.3);
    --ethead: #707070;
    --eheader-border: #a8a8a8;
    --elaps-active: rgb(241, 241, 241);
    --enav-bg: #323232;
    --eprotest-bg: #fff;
}



[data-theme="dark"] {
    --ebg-color: #323232;
    --ealmost-white: #444444;
    --eh-color: #ccc;
    --eblue: #311559;
    --ebluehover: #311559;
	--ehuge-shadow: 0px -5px 29px -5px rgba(0, 0, 0, 0.35);
    --ebg-gradient: linear-gradient(to bottom, #651c93 0%, #020f26 350px, #030606 370px, rgba(255, 255, 255, 0) 371px, rgba(255, 255, 255, 0) 100%);
    --einfo-label: #414141;
    --ered: darkred;
    --egreen: darkgreen;
    --elabel-shadow: 3px 7px 7px 0px rgba(32, 19, 72, 0.22);
    --esession-border: 2px solid #2e2b37;
    --emain-white-hover: #3d3d3d;
    --elaps-active: #252525;
    --eblack: #929292;
    --edarkblue: #230f3f;
    --eccc: #444444;
    --efavs: #444444;
    --efafafa: #1d1d1d;
    --ef0f0f0: #1d1d1d;
    --eprofile-shadow: rgba(32, 19, 72, 0.22);
    --ethead: #a7a7a7;
    --eheader-border: #282828;
    --enav-bg: #242424;
    --eprotest-bg: #3c3c3c;
}
$blue: #4778ce;
$bluehover: #6591de;

body
{

	h1, h2, h3, h4, h5
	{
		font-family: 'OpenSans-Bold';
		color: var(--eh-color);
	}
	// p
	// {
	background-color: var(--ebg-color);
	// }
    &.white
    {
        background: #fff;
    }
    transition: background-color 0.3s ease;
    transition: color 0.3s ease;
    .placeholder {
        display: inline-block;
        min-height: 1em;
        vertical-align: middle;
        cursor: wait;
        background-color: currentColor;
        opacity: .5;
        animation: placeholder-glow 2s ease-in-out infinite; 
    }
}
@keyframes placeholder-glow {
    50% {
      opacity: .2;
    }
  }
@mixin button($color, $hover)
{
	color: #fff;
		background: $blue;
		text-decoration: none;
		min-height: 54px;
		text-align: center;
		padding: 5px;
		width: 230px;
		display: flex;
		justify-content: center;
		align-items: center;
		text-transform: uppercase;
		border-radius: 5px;
		font-size: 'OpenSans-SemiBold';
		-webkit-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.47);
		-moz-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.47);
		box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.47);
		transition: all .3s ease-in-out;
		margin: 3px;
		&:hover
		{
			background: $hover;
			transition: all .3s ease-in-out;
			transform: scale(1.005);
		}
}
@mixin link($color, $hover)
{
	color: $color;
	font-family: 'OpenSans-SemiBold';
	text-decoration: none;
	&:hover
	{
		border: 0;
		color: $hover;
	}
}
.blured
{
	filter: blur(4px);
}

@import "header";
@import "btn";
@import "nav";
@import "profile";
@import "profile_new";

@import "login";
@import "table";
@import "form";
@import "create-event";
@import "tab";
@import "protest";
@import "series";
@import "pagination";
@import "categories";
@import "class";
@import "stats";
@import "premium";
@import "skins";
@import "modal";
@import "home";
@import "vdes";
@import "balance";
@import "pteam";
@import "pteams";
@import "mobile";
@import "setup_hub";
@import "server";
@import "achievements";
@import "bl";
@import "tg";
@import "loader";