.event
{
    background: var(--ebg-gradient);
    margin-top: 0px;
    padding-top: 120px;
    padding-bottom: 80px;

    z-index: -1;
    iframe
    {
        max-width: 100%;
    }
    &-list
    {
        color: var(--eblack);
        a
        {
            text-decoration: none;
        }
        padding: 40px;
        background: var(--ebg-color);
        -webkit-box-shadow: 0px -5px 29px -5px rgba(0,0,0,0.35);
        -moz-box-shadow: 0px -5px 29px -5px rgba(0,0,0,0.35);
        box-shadow: 0px -5px 29px -5px rgba(0,0,0,0.35);
        h2
        {
            font-family: 'OpenSans-ExtraBold';
            font-size: 40px;
        }
        &__header
        {
            font-family: 'OpenSans-Bold';
            font-size: 30px;
        }

    }
    &-stream
    {
        #stream-form
        {
            display: none;
        }
    }
    &-streams
    {
        display: flex;
        flex-wrap: wrap;
        &-item
        {
            /*flex: 1 1 30%;
            max-width: 100%;
            margin-right: 20px;
            margin-top: 20px;*/
            margin-bottom: 20px;

            iframe,img
            {
                max-width: 100%;
                object-fit: cover;
            }
            &__name
            {
                font-family: 'OpenSans-SemiBold';
                font-size: 0.8rem;
                a
                {
                    color: #000000;
                    text-decoration: none;
                    &:hover

                    {
                        text-decoration: underline;
                    }
                }
            }
        }
        &-delete
        {
            position: absolute;
            top: -0.5rem;
            right: 0.3rem;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgb(226, 1, 1);
            width: 1.4rem;
            height: 1.4rem;
            border-radius: 100%;
            transition: all .2s ease;
            -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
            -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
            box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
            a
            {
                font-size: 1.15rem;
                color: #ffffff;
                text-decoration: none;
                .fa-times
                {
                    display: block;
                }
            }
            &:hover
            {
                transform: scale(1.3);
                transition: all .2s ease;
                -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.75);
                -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.75);
                box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.75);
            }
        }
        &-btns
        {
            display: flex;
            gap: 1rem;
            &__btn
            {
                font-size: 2rem;
                padding: 1rem 3rem;
                border-radius: 10px;
                border: 2px solid var(--eblack);
                transition: all .2s ease;
                &:hover
                {
                    cursor: pointer;
                    background: var(--ealmost-white);
                }
                &.yt
                {
                    &.checked
                    {
                        color: var(--ealmost-white);
                        background: #ff0000;
                        border-color: #ff0000;
                        transition: all .2s ease;
                    }

                }
                &.tw
                {
                    &.checked
                    {
                        color: var(--ealmost-white);
                        background: #8b44f7;
                        border-color: #8b44f7;
                        transition: all .2s ease;
                    }
                }
            }
        }

    }
    &-series
    {
        &__logo
        {
            max-width: 7rem;
        }
    }
    &-filters
    {
        background: var(--ealmost-white);
        padding: 2rem;
        margin-bottom: 2rem;
        border-radius: 1rem;
        display: none;
        ::-webkit-calendar-picker-indicator {
            filter: invert(0.6);
        }
        &.flat
        {
            padding: 0rem 0rem 1rem 0rem;
        }
        .form
        {
           flex-wrap: wrap; 
           &-item
           {
            max-width: 330px;
           }
        }
        &-wrapper
        {
            position: relative;
        }
        &__btn
        {
            position: absolute;
            top:-3.2rem;
            font-size: 2rem;
            right: 0;
            color: #707070;
            transition: all .3s ease;
            display: flex;
            justify-content: center;
            flex-direction: column;
            span
            {
                text-align: center;
                font-size: 0.55rem;
                display: none;
            }
            i
            {
                text-align: center;
            }
            &.active
            {
                transition: all .3s ease;
                color: #4778ce;
                span
                {
                    display: block;

                }
            }
        }
        &-list
        {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
            margin: 0;
            padding: 0;
            div
            {
                margin: 0.5rem;
            }
            input
            {
                display: none;
                &:checked + label
                {
                    background: #4778ce;
                    color: #fff;
                    transition: 0.2s all ease;
                }
            }
            label
            {
                border-radius: 0.5rem;
                padding: 0.5rem 1rem;
                box-shadow: 0px 0px 1px 2px rgba(0,0,0,0.25);
                background: #fff;
                transition: 0.2s all ease;
                display: block;
                font-family: 'OpenSans-SemiBold';
                transition: .3s all ease;
                &:hover
                {
                    cursor: pointer;
                    transform: scale(1.02);
                    transition: .3s all ease;
                }
            }
        }
    }
    &-hotlap
    {
        padding: 1rem;
        box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 5px 0px;
        background: rgb(56,56,56);
        background: linear-gradient(125deg, rgba(56,56,56,1) 64%, rgba(71,120,206,1) 65%, rgba(52,95,170,1) 83%, rgba(147,0,6,1) 84%, rgba(115,0,5,1) 100%);
        border-radius: 1rem;
        &-row
        {
            justify-content: space-between;
            align-items: center;
        }
        &__link
        {
            font-size: 2rem;
            box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 5px 3px;
            transition: all .2s ease;
            &:hover
            {
                background: #008131;
                transition: all .2s ease;
            }
        }
        h3
        {
            color: #fafafa;
            text-shadow: 0px 0px 4px black;
        }
    }
    &-share
    {
        display: none;
        .form
        {
            padding-top: 0!important;
        }
        &-user
        {
            padding: 0.2rem 0.5rem;
            display: flex;
            box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.3);
            border-radius: 3px;
            align-items: center;
            width: 100%;
            margin-bottom: 0.5rem;
            position: relative;
            cursor: pointer;
            &.active
            {
                background: var(--ebluehover);
                color: var(--ealmost-white);
            }
            &__avatar
            {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                overflow: hidden;
                margin-left: 1rem;
                margin-right: 1.5rem;
                img
                {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
            p
            {
                font-family: 'OpenSans-SemiBold';
            }
        }
        &-list
        {
            margin-bottom: 1rem;
            margin-top: -1rem;
        }
    }
    &-search
    {
        padding: 0 40px;
    }
    &-ballast
    {
        background: rgb(192, 0, 0);;
        color: #fff;
        border-radius: 4px;
        font-size: 0.8rem;
        padding: 0.1rem 0.3rem;
    }
}
.table
{
    font-family: 'OpenSans-Regular';
    width: 100%;
    border-radius: 5px;
    overflow-x: auto;

    .driver-link
        {
            color: var(--eblack);
            font-weight: 600;
            text-decoration: none;
            display: flex;
            &:hover
            {
                text-decoration: underline;
            }
            &-premium
            {
                padding: 3px 8px;
                border-radius: 3px;
                color: #fff;

            }
        }
    .driver-avatar
    {
        width: 1.6rem;
        height: 1.6rem;
        margin-top: -0.3rem;
        border-radius: 50%;
        margin-right: 1.5rem;
        box-shadow: 0px 2px 2px 2px #4778ce;
        display: inline-block;
        & img
        {
            object-fit: cover;
            width: 100%;
            border-radius: 50%;
            height: 100%;
        }
    }
    &-event, &-laps
    {
        transition: .3s all;
        background-color: var(--ebg-color);
        color: var(--eblack);

        &.active
        {
            transition: .3s all;
            background-color: var(--elaps-active);
            cursor: pointer;
        }
        &:last-child
        {
            border-bottom: 3px solid #fff ;
        }
        &-more
        {
            width: 30px;
            .fa-chevron-down
            {
                font-size: 20px;
                transition: .3s all ease-in-out;
                transform-origin: center;
                &.active
                {
                    transform: rotate(180deg);
                    transition: .3s all ease-in-out;
                }
            }
        }
        &__icon
        {
            max-width: 1.8rem;
            // display: block;
            // margin: auto;
        }
    }
    &-laps
    {
        background: rgb(49, 49, 49);
        color: #ffffff!important;
        display: none;
        &-item
        {
            padding: 0!important;
            table
            {
                -webkit-box-shadow: inset 0px 0px 5px 2px rgba(0,0,0,0.75);
                -moz-box-shadow: inset 0px 0px 5px 2px rgba(0,0,0,0.75);
                box-shadow: inset 0px 0px 5px 2px rgba(0,0,0,0.75);
                width: 100%;
                color: #ffffff;
            }
            
        }
        
        &.active
        {
            background: var(--enav-bg);
            display: table-row;
            color: #ffffff;
        }
        .personal_best
        {
            color: #0fdc0f;
            font-weight: 500;
        }
        .absolute_best
        {
            color: #e2004c;
            font-weight: 500;
        }
        .invalid
            {
                color: #979797;
            }
    }
    thead
    {
        tr
        {
            font-family: 'OpenSans-Bold';
            color: var(--eblack);
        }
        border-bottom: 2px solid rgb(170, 169, 169);
    }
    td, th
    {
        padding: 5px;
        text-align: left;
        padding: 15px 10px;
        &:first-child
        {
            width: 5.5rem;
        }
    }
    tr
    {
        border-bottom: 2px solid rgb(170, 169, 169);
        // &:nth-child(2n+1)
        // {
        //     background: #fafafa;
        // }
        // &:hover
        // {
        //     background:  rgb(224, 224, 224);
        // }


        &:last-child
        {
            border: none;
        }

    }
    .confurm, .reg, .stream,  &.event-confurm,  &.event-stream
    {
        .form
        {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;
        }
        display: none;
        &.active
        {
            display: block;
        }
    }
    &.event-reg
    {
        overflow-x: inherit;
        .form
        {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;
            
        }
    }
    .check-in
            {
                display: flex;
                justify-content: flex-end;
                flex-wrap: wrap;

                @media screen and (max-width: 768px) {
                    .btn
                    {
                        margin-bottom: 0.2rem;
                    }
                }
                &__btn
                {


                }
                &-event
                {
                    justify-content: flex-start;
                }

                .btn
                {
                    margin-left: 25px;
                }
            }
    .info
    {

        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        &__btn
        {
            margin-left: 0.1rem;
        }
    }
    &-responsive-sm
    {
        overflow-x: auto;
    }
    .sessions
    {


        border: none;
        transition: .3s all;

        &-information
        {
            max-width: 100%;
            margin-right: 25px;
            margin-top: 20px;
            margin-bottom: 20px;
            padding: 80px 20px 20px 40px;
            flex-wrap: wrap;
            justify-content: flex-start;
            background: var(--einfo-label);
            color: var(--eblack);
            position: relative;
            border-radius: 18px;
            -webkit-box-shadow: var(--elabel-shadow);
            -moz-box-shadow: var(--elabel-shadow);
            box-shadow: var(--elabel-shadow);
            &.prize
            {
                background: #254d92;
                p
                {
                    color: #fafafa;
                    font-family: 'OpenSans-SemiBold';
                }
                .price
                {
                    background: #fff;
                    padding: 3px;
                    color: #254d92;
                    border-radius: 0.3rem;
                    margin: 0 3px;
                }
            }
            &.charity
            {
                background: #8f1c1c;
                background-position: right bottom;
                overflow: hidden;
                p
                {
                    color: #fafafa;
                    font-family: 'OpenSans-SemiBold';
                    width: 70%;
                }
                .price
                {
                    background: #fff;
                    padding: 3px;
                    color: #254d92;
                    border-radius: 0.3rem;
                    margin: 0 3px;
                }
                a
                {
                    color: #fff;
                    text-decoration: underline;
                    &:hover
                    {
                        text-decoration: none;
                    }
                }
                img
                {
                    display: block;
                    position: absolute;
                    bottom: 0;
                    object-fit: contain;
                    /* width: 100%; */
                    height: 100%;
                    right: 0;
                    opacity: 0.4;
                    mix-blend-mode: hard-light;
                    @media screen and (max-width: 478px) {
                        margin: -25px -100px -25px;
                        position:static;

                    }
                }
            }
            &-content
            {
                display: flex;
                flex-wrap: wrap;
                justify-content: start;
            }
            &__item
            {
                flex: 1 1 280px;
                max-width: 327px;
                margin-bottom: 15px;
            }
            &__name
            {
                font-weight: 600;
            }
            .fa-check-circle
            {
                font-size: 20px;
                color: green;
            }
            .fa-times-circle
            {
                font-size: 20px;
                color: red;
            }
        }
        &-items
        {

            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
            overflow: hidden;
            transition: .3s all;
            &.active
            {
                height: auto;
                transition: .3s all;
            }

        }
        &-item
        {
            flex: 1 1 30%;
            max-width: 100%;
            margin-right: 20px;
            margin-top: 20px;
            margin-bottom: 20px;
            padding: 80px 20px 20px 40px;
            flex-wrap: wrap;
            justify-content: flex-start;
            background: var(--einfo-label);
            position: relative;
            border-radius: 18px;
            -webkit-box-shadow: var(--elabel-shadow);
            -moz-box-shadow: var(--elabel-shadow);
            box-shadow: var(--elabel-shadow);
            &.reverse{
                position: relative;
                // &:after
                // {
                //     content: 'server restart';
                //     position: absolute;
                //     top: 0;
                //     right: 0;
                //     transform: rotateY(90);
                // }
            }
        }
        &-type
        {
            position: absolute;
            top: -30px;
            left: -6px;
            font-family: 'OpenSans-ExtraBold';
            font-size: 80px;
            color: var(--ebg-color);

        }
        &-info
        {

            tr, td
            {
                background: var(--einfo-label);
                color: var(--eblack);
            }
            td:nth-child(2n+1)
            {
                font-family: 'OpenSans-Bold';
            }
            td:nth-child(2n)
            {
                font-family: 'OpenSans-Regular';
            }
        }
    }
    span.seperator
        {
            font-weight: 600;
            &:last-child
            {
                display: none;
            }
        }
    &.entrylist
    {
        margin-bottom: 30px;

        tbody
        {
            tr
            {
                border: none;
                &:hover
                {
                    background: var(--ef0f0f0);
                }
            }
        }

        td
        {
            &:last-child
            {
                position: relative;
            }
        }
        span.number
        {
            position: absolute;
            right: 0;
            top: 8px;
            font-weight: 600;
            width: 38px;
            text-align: center;
            background: #4778ce;
            color: #fff;
            padding: 5px 5px;
            border-bottom-right-radius: 7px;

                &.am
                {
                    background: #c00000;
                    color: #ffffff;
                }
                &.silver
                {
                    background: rgb(100, 100, 100);
                    color: #ffffff;
                }

                &.proam
                {
                    background: rgb(15, 15, 15);
                    color: #ffffff;
                }

                &.pro
                {
                    background: #ffffff;
                    color: rgb(15, 15, 15);
                    // padding: 0.1rem 0.4rem;
                    border: 0.1rem solid #000000;
                }

        }
        &.reserve
        {
            background: #d8d8d8;


            h3
            {
                margin: 0;
            }
        }
    }
    &-flag
    {
        width: 1.2rem;
        height: 1.2rem;
        border-radius: 50%;
        // margin-left: 1.3rem;
        -webkit-box-shadow: 1px 1px 4px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 1px 1px 4px 0px rgba(0,0,0,0.75);
        box-shadow: 1px 1px 4px 0px rgba(0,0,0,0.75);
    }
    .completed-link
    {
        color: #383838;
    }
    &-profile-avatar
    {
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
        overflow: hidden;
        margin-right: 1rem;
        img
        {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &-profile-name
    {
        min-width: 15rem;
        display: flex;
        align-items: center;
    }
    .ext_stat
    {
        width: 100%;
        box-shadow: 0px 10px 11px -6px rgba(0, 0, 0, 0.1);
        margin-bottom: 3rem;
        color: var(--eblack);
        background: var(--eprotest-bg);
        thead
        {
            background: var(--ethead);
            color: var(--ebg-color);
            font-family: 'OpenSans-SemiBold';
        }
        tbody
        {
            font-family: 'OpenSans-Bold';
            text-align: center;
            color: var(--eblack);
        }
        td
        {
            border: 1px solid var(--ebg-color);
            padding: 0.8rem;
            text-align: center;
        }
        th
        {
            border: 1px solid var(--ebg-color);
            color: var(--ebg-color);
            padding: 0.3rem;
            text-align: center;
            &:first-child {
                width: 33.3%;
            }
        }
        &-sector
        {
            // display: inline-block;
            img
            {
                margin-right: 0.3rem;
            }
            .driver-link
            {
                display: inline;
            }
        }
    }
    .car_year
    {
        font-size: 0.3rem;
        border-radius: 0.2rem;
        padding: 0.4rem;
        color: #fff;
        .gt3
        {
            background: #fd0000;
            .oldschool
            {
                background: #ad0101;
            }
        }
        .gtc
        {
            background: #00832c;
        }
        .tcx
        {
            background: #004483;
        }
    }
    
    &-ballast
    {
        background: rgb(192, 0, 0);;
        color: #fff;
        border-radius: 4px;
        font-size: 0.8rem;
        padding: 0.1rem 0.3rem;
        width: fit-content;
    }
}

@media screen and (max-width: 568px) {
    .table {
      display: block;
      &.event-share
      {
        display: none;
      }
    }
  }
