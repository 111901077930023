.pteams
{
    &-item
    {
        height: 20rem;
        overflow: hidden;
        margin-bottom: 2rem;
        border-radius: 1rem;
        -webkit-box-shadow: 0px 0px 8px 2px rgba(34, 60, 80, 0.35);
        -moz-box-shadow: 0px 0px 8px 2px rgba(34, 60, 80, 0.35);
        box-shadow: 0px 0px 8px 2px rgba(34, 60, 80, 0.35);
        &:hover
        {
            .pteams-logo
            {
                margin-top: -20rem;
                transition: all .3s ease;
            }
        }
    }
    
    &-logo
    {
        transition: all .3s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        &__img 
        {
            padding: 3rem;
            width: 100%;
        }
        h2
        {
            color: #fafafa;
            text-align: center;
        }
    }
    &-content
    {
        background: rgba(255, 255, 255, 0.6);
        height: 100%;
        padding: 1.5rem;
        backdrop-filter: blur(5px);
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        h3
        {
            text-align: center;
        }
    }
    &-link
    {
        @include button($blue, $bluehover); 
            text-align: center;
		    border: none;
            margin: 3px auto 3rem;
        &.buy{

            @media screen and (min-width:768px) {
                position: absolute; 
                right: 1rem;
                top: -2rem;
            }
        }    
    }
}