.modal
{
    position: fixed;
    background: rgba(255,255,255,0.2);
    display: none;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    backdrop-filter: blur(20px);
    p, ul
    {
        color: var(--eblack)
    }
    &-form
    {
        border: 1px solid var(--ealmost-white);
        background: var(--ebg-color);
        padding: 0 2rem 2rem;
        border-radius: 0.5rem;
        -webkit-box-shadow: 0px 2px 20px 0px rgba(0,0,0,0.3);
        -moz-box-shadow: 0px 2px 20px 0px rgba(0,0,0,0.3);
        box-shadow: 0px 2px 20px 0px rgba(0,0,0,0.3);
        .agree
        {
            text-align: center;
            display: block;
            margin: auto;
            &:hover
            {
                cursor: pointer;
            }
        }
    }
    &.active
    {
        display: flex;
    }
}
