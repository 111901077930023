.mobile
{
    @media screen and (min-device-width: 720px) and (min-device-height: 568px) {
		display: none;
	}

    &-nav
    {
        background: rgba(0, 0, 0, 0.7);
        position: fixed;
        bottom: -100vh;
        transition: .3s all ease;
        width: 100%;
        z-index: 10;
        backdrop-filter: blur(4px);
        box-shadow: 2px 0px 15px 0px rgba(0, 0, 0, 0.8);
        border-top: 1px solid #7e7e7e;
        @media screen and (max-device-width: 568px) 
        {
            padding-bottom: 2.1rem;
        }
        &-body
        {
            ul 
            {
                list-style: none;
                padding-left: 0;
                margin-left: 0;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                @media screen and (max-device-width: 568px) {
                    grid-template-columns: 1fr 1fr 1fr;
                }
            }
        }
        &-item
        {
            &__link
            {   
                            
                color: #fff;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-size: 0.6rem;
                padding: 0.8rem;
                text-decoration: none;
                & i
                {
                    font-size: 1.5rem!important;
                }
                &:hover
                {
                    background: #545454;
                    transition: all .3s ease-out;
                }
            }
        }
        &.active
        {
            bottom: 0;
            transition: .3s all ease;
        }
        &-button
        {
            position: fixed;
            font-size: 1.7rem;
            width: 3rem;
            height: 3rem;
            right: 2rem;
            bottom: 2rem;
            padding: 1re;
            border-radius: 50%;
            background: #4778ce;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.8);
            z-index: 100;
            a.mobile-nav-link
            {
                color: #fff;
            }
        }
        &-icons
        {
            margin: 1rem auto;
            padding: 1.5rem;
            border-top: 2px solid rgb(143, 143, 143);
            width: calc(100% - 7rem);
            display: flex;
            color: #fefefe;
            font-size: 2rem;
            justify-content: space-around;
            a 
            {
                color: #e4e4e4;
                transition: all .2s ease;
                margin: 0 0.3rem;
            }
            a:hover 
            {
                transform: scale(1.1);
                transition: all .2s ease;
            }
            a:hover .fa-discord
            {
                color: #5865F2;
            }
            a:hover .fa-youtube
            {
                color: #FF0000;
            }
            a:hover .fa-instagram
            {
                color: #833AB4;
            }
            a:hover .fa-vk
            {
                color: rgb(89,125,163);
            }
            a:hover .fa-patreon
            {
                color: #ff424d;
            }
        }
    }
}