.tab
{
    &-nav
    {
        margin-top: 20px;
        margin-bottom: 30px;
        display: flex;
        text-transform: uppercase;
        list-style: none;
        // justify-content: ;
        padding: 0;
        -webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.3);
        -moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.3);
        box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.3);
        overflow-x: auto;
        &-item
        {
            flex: 1 135px;
            text-align: center;
            width: 136px;
            color: var(--eh-color);
            font-weight: 600;
            display: block;
            text-decoration: none;
            padding: 20px;
            &.active
            {
                color: rgba(71,120,206,1);
                border-bottom: 3px solid rgba(71,120,206,1);
            }
            &:hover
            {
                background: var(--emain-white-hover);
            }
        }
    }
    &-session
    {
        display: none;

        &.active
        {
            display: block;
        }
    }
}
