.home
{
    height: 100vh;
    background: rgb(255,255,255);
    background: linear-gradient(155deg, rgba(255,255,255,1) 0%, rgba(236,236,236,0.76) 100%);
    &-wrap
    {
        overflow: hidden;
        height: 100vh;
        margin: 0 -15px;
    }
    &-container
    {
        // position: absolute!important;
        // top: 0!important;
        display: block;
        position: relative;
        top: 0;
        transition: all .3s ease-in-out;
    }
    &-description
    {
        min-height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3rem;
        position: relative;
        padding: 3rem;
        overflow: hidden;
    }
    &-login
    {
        position: relative;
        padding: 3rem;
        min-height: 100vh;
        display: flex;
        align-items: center;
    }
    &-header
    {
        font-size: 7rem;
        position: absolute;
        z-index: 0;
        top: 1rem;
        width: 100vw;
        // transform: translateX(-6rem);
        left: -6rem;
        font-family: 'OpenSans-ExtraBold';
        color: #707070;
        opacity: 0.08;
        &.slide
        {
            animation: slide 25s ease-in-out 0s 1 normal forwards;
            
        }
    }
    &-main
    {
         display: block;
         h1 
         {
             font-family: 'OpenSans-ExtraBold';
             font-size: 5rem;
             text-align: center;
             color: #707070;
             margin-top: 0rem;
             position: relative;
             &:after 
             {
                content: '';
                position: absolute;
                border-bottom: solid 1.2rem #4778CE;
                width: 13rem;
                bottom: -1rem;
                left: -6rem;
             }
         }
         p 
         {
            font-family: 'OpenSans-SemiBold'; 
            color: #707070;
         }
         &__description
         {
            font-size: 1rem;
            margin-bottom: 50px;
         }
         &__sub
         {
             font-size: 0.8rem;
             margin: 0.8rem auto;
             text-align: center;
            a 
            {
                text-decoration: none;
                color: #4778CE;
                &:hover
                {
                    text-decoration: underline;
                }
            }
         }
    }
    &-beauty
    {
        height: 100vh;
        margin: 0 -15px;
        overflow: hidden;
        position: relative;
        video
        {
            position: absolute;
            z-index: 1;
            filter: grayscale(1) brightness(0.45) contrast(1.05);
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
        &-features
        {
            height: 100vh;
            display: grid;
            grid-template-columns: 1fr 1fr;
            position: relative;
            z-index: 10;
            & > div 
            {
                border: solid 1px #3f3f3f;
            }
        }
        &-logo
        {
            grid-column-start: 1;
            grid-column-end: 3;
            display: flex;
            align-items: center;
            justify-content: center;
            animation: item 7s infinite;
            img 
            {
                width: 25rem;
                max-width: 100%;
            }
        }
        &-item
        {
            color: #ffffff;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            
            &:nth-child(1)
            {
                animation: item 8.5s 1s infinite;
            }
            &:nth-child(2)
            {
                animation: item 7.8s 2.2s infinite;
            }
            &:nth-child(3)
            {
                animation: item 8.3s 1.8s infinite;
            }
            &:nth-child(4)
            {
                animation: item 7.7s 3s infinite;
            }
            &:nth-child(5)
            {
                animation: item 8.2s 1.3s infinite;
            }
            &:nth-child(6)
            {
                animation: item 8s 3.3s infinite;
            }
            &:nth-child(7)
            {
                animation: item 8.4s 1.6s infinite;
            }
            &:nth-child(8)
            {
                animation: item 7.8s 2.6s infinite;
            }
            i 
            {
                font-size: 4rem;
            }
            p
            {
                font-family: 'OpenSans-SemiBold'; 
                font-size: 1rem;
                text-align: center;
            }
        }
    }
    &-btn
    {
        @include button($blue, $bluehover); 
        border: none;
        margin: auto;
    }
    &-form
	{
		min-height: 40px;
		width: 100%;
        padding: 40px;
        z-index: 2;
		-webkit-box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.3);
		-moz-box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.3);
		box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.3);
		&__h1
		{
			margin: 0;
			text-align: center;
			font-family: "OpenSans-Bold";
			color: #151515;
		}
		&-item 
		{
			width: 100%;
			position: relative;
			label
				{
					top: 15px;
					left: 15px;
					font-size: 12px;
					font-weight: 600;
					font-family: "OpenSans-SemiBold";
					position: absolute;
					z-index: 3;
					font-size: 20px;
					color: #959595;
					transition: all .2s ease;
					
				}
			&__input
				{
					border: none;
					border-bottom: 3px solid grey;
					width: 90%;
					padding: 7px;
					font-size: 20px;
					font-family: "OpenSans-SemiBold";
					position: relative;
					
					&:focus
					{
						border: none;
						color: $blue;
						border-bottom: 3px solid grey;
					}
				}
				&:after 
					{
						content:'';
						width: 90%;
						display: block;
						height: 10px;
						position: absolute;
						bottom: 10px;
						left: 2%;
						right: 2%;
						margin-left: 0;
						margin-right: 0;
						border-bottom: 3px solid $blue;
						transform-origin: center center;
						transform: scaleX(0);
						z-index: 3;
						transition: all .2s ease;
					}
			
			&.active
			{
				label
					{
						top: 0px;
						font-size: 12px;
						transition: all .3s ease;
						color: $blue;
                        
					}
				.login-form-item__input
				{
					&:focus
					{
						
						outline: none;
					}
					
				}
				&:after
				{
					transform: scaleX(1);
					transition: all .3s ease;
				}
				p.sub
					{
						color: rgb(228, 0, 0);
					}
			}
		}
		form 
		{
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			flex-direction: column;
			align-items: center;
			
			input 
			{
				margin: 10px;
				position: relative;
				
				
				&[type="submit"]
				{
					@include button($blue, $bluehover);
					display: block;
					border-width: 0px;
					border-style: none;
					border-color: none;
					border-image: none;
				}
			}
		}
		p 
		{
			text-align: center;
			font-family: "OpenSans-Regular";
			font-size: 14px;
			color: #959595;
			a 
			{
				@include link($blue, $bluehover);
			}
			&.sub
			{
				right: 50%;
				width: 100%;
				margin-right: -50%;
				position: absolute;
				bottom: -22px;
				font-size: 12px;
				text-align: center;
			}
		}
    }
    &-error
    {
        margin: 1rem;
        background: red;
        color: #fff;
        padding: 0.4rem 0.7rem;
        font-family: 'OpenSans-SemiBold';
        font-size: 1.1rem;
        border-radius: 0.3rem;
        // display: none; 
    }
}
@keyframes slide 
{
    from {left: -6rem; transform: scale(1);}
    to {left: -3rem; transform: scale(1.04);}
}
@keyframes item
{
    0% {
        background: rgba(255,255,255,0.0);
    }
    20% {
        background: rgba(255,255,255,0.2);
        
    }
    80% {
        background: rgba(255,255,255,0.2);
        
    }
    100% {
        background: rgba(255,255,255,0.0);

    }
}
@media screen and (max-width: 568px)
{
    .home 
    {
        &-main
        {
            h1 
            {
                font-size: 2rem;
                margin-bottom: 2rem;
                &:after 
                {
                    bottom: -1.5rem
                }
            }
            &__description
            {
                font-size: 0.8rem;
            }
        }
    }
}
@media screen and (max-height: 568px)
{
    .home 
    {
        &-main
        {
            h1 
            {
                font-size: 2rem;
                margin-bottom: 2rem;
                &:after 
                {
                    bottom: -1.5rem
                }
            }
            &__description
            {
                font-size: 0.8rem;
                margin-bottom: 1rem;
            }
        }
        &-description
        {
            padding: 1rem;
        }
        &-header
        {
            font-size: 6rem;
        }
    }
}
@media screen and (min-width: 1440px)
{
    .home
    {
        &-header
        {
            font-size: 10rem;
            top: 7rem;
        }
        &-description
        {
            padding: 6rem;
        }
    }
}