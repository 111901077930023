.tg
{
    background: var(--ebg-color);
    &-main
    {
        background: url('../../img/telegram/bg-1.svg') no-repeat;
        background-position: center -5rem;
        background-size: cover;
        min-height: 750px;

        position: relative;
        overflow: hidden;
        @media screen and (max-width: 578px)
        {
            background-position: -50rem -3rem;
            background-size: 360%;
        }
        &-logo
        {
            width: 10rem;
            margin-left: 3rem;
            margin-top: 3rem;
            position: relative;
            z-index: 10;
            @media screen and (max-width: 578px)
            {
                margin: 2rem auto -6rem;
            }
        }
        &-content
        {
            width: 100%;
            min-height: 100vh;
            margin-top: -5rem;
            // background: url('../../img/telegram/bg1.png') no-repeat;
            // background-position: -35rem center;
            // background-size: 200%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            z-index: 5;
            h1
            {
                color: var(--eh-color);
                text-align: center;
                font-weight: 800;
                font-size: 3rem;
                margin-bottom: 1.5rem;
            }
            p
            {
                color: var(--eh-color);
                font-weight: 600;
                font-size: 1.7rem;
                text-align: center;
                margin-bottom: 1.5rem;
                width: 480px;
                max-width: 95vw;
            }
        }
        &-phone
        {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            max-height: 100vh;
            // max-height: 90vh;
            img
            {
                max-width: 100%;
                max-height: 100%;
            }
        }
        &-bg
        {
            position: absolute;
            bottom: 0;
            left:-55%;
            width: 120vw;
            height: 60vh;
            background: url('../../img/telegram/bg1.png') no-repeat;
            background-position: bottom right;
            background-size: contain;
            z-index: -1;
            opacity: 0.4;
            @media screen and (max-width: 578px) {
                position: absolute;
                bottom: 0;
                top: 16rem;
                left: -55%;
                width: 200vw;
                height: 70vh;
            }
            @media screen and (min-width: 1920px) {
                left: -137%;
                width: 200vw;
                height: 70vh;
            }
        }
        &-btn
        {
            display: flex;
            justify-content: center;
        }
    }
    &-second
    {
        background: url('../../img/telegram/bg-2.png') no-repeat;
        background-position: center 7rem;
        background-size: cover;
        min-height: 750px;
        position: relative;
        @media screen and (max-width:578px) {
            background-position: -35rem bottom;
            background-size: 300%;
        }
        @media screen and (min-width: 1921px)
         {
            background-position: center -10rem;
                background-size: 110%;
        }
        &-bg
        {
            position: absolute;
            background: url('../../img/telegram/bg-2.svg') no-repeat;
            height: 40rem;
            width: 100%;
            z-index: -1;
            background-size: 200%;
            background-position: center -19rem;
            backdrop-filter: blur(20px);
            @media screen and (min-width: 1921px)
            {
                height: 45rem;
                width: 100%;
                z-index: -1;
                background-size: 350%;
                background-position: 59% -44rem;
                -webkit-backdrop-filter: blur(20px);
                backdrop-filter: blur(20px);
            }
            @media screen and (max-width: 578px)
            {
                height: 70rem;
                width: 100%;
                z-index: -1;
                background-size: 612%;
                background-position: -24rem -2rem;
                -webkit-backdrop-filter: blur(20px);
                backdrop-filter: blur(20px);
            }
        }
        &-video
        {
            width: 20rem;
            max-width: 90wv;
            margin: 3rem auto;
            overflow: hidden;
            border-radius: 3rem;
            -webkit-box-shadow: 0px 0px 53px 3px rgba(0,0,0,0.75);
            -moz-box-shadow: 0px 0px 53px 3px rgba(0,0,0,0.75);
            box-shadow: 0px 0px 53px 3px rgba(0,0,0,0.75);
            video
            {
                max-width: 100%;
            }
        }
        &-content
        {
            height: 100%;
            display: flex;
            justify-content: center;
            padding: 6rem 1.5rem;
            align-items: center;
        }
        &-text
        {
            height: fit-content;
            width: 30rem;
            max-width: 90vw;
            padding: 4rem 5rem;
            background: rgba(166, 58, 255, 0.7);
            background: linear-gradient(180deg, rgba(180, 87, 255, 0.6) 0%, rgba(91, 10, 128, 0.6) 100%);
            backdrop-filter: blur(15px);
            border-radius: 3rem;
            @media screen and (max-width: 578px)
            {
                padding: 3rem;
            }
            h2
            {
                color: #fff;
                text-transform: uppercase;
                tab-size: 2rem;
                text-align: center;
                margin-bottom: 1rem;
            }
        }
        &-btn
        {
            display: flex;
            justify-content: center;
        }
    }
    &-third
    {
        height: 100vh;
        background: url(../../img/telegram/bg-3.jpg) ;
        background-size: cover;
        @media screen and (max-width:578px) {
            background-position: -54rem bottom;
        }
        &-content
        {
            height: 100%;
            display: flex;
            justify-content: center;
            padding: 6rem 1.5rem;
            align-items: center;
        }
        &-text
        {
            height: fit-content;
            width: 30rem;
            max-width: 90vw;
            padding: 4rem 5rem;
            background: rgba(166, 58, 255, 0.7);
            background: linear-gradient(180deg, rgba(180, 87, 255, 0.6) 0%, rgba(91, 10, 128, 0.6) 100%);
            backdrop-filter: blur(15px);
            border-radius: 3rem;
            @media screen and (max-width: 578px)
            {
                padding: 3rem;
            }
            h2
            {
                color: #fff;
                text-transform: uppercase;
                tab-size: 2rem;
                text-align: center;
                margin-bottom: 1rem;
            }
        }
        &-btn
        {
            display: flex;
            justify-content: center;
        }
    }
    &-event {
        background: #229ED9;
        color: #fff;
        width: 100%;
        height: calc(100% - 1.5rem);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

         h2 {
            font-weight: 800;
            text-align: center;
            color: #fff;
        }

         i {
            color: #fff;
            font-size: 5rem;
        }
    }

}
