.btn
{
    display: flex;
    align-items: center;
    width: fit-content;
    i
    {
        margin-right: 0.3rem;
        // margin-left: 0.3rem;

        // display: flex;
        // align-items: center;
        font-size: 1rem;
    }
    &-default
    {
        background: var(--eprotest-bg);
        border: 2px solid var(--ethead);
        color: var(--eh-color);
        &:hover
        {
            background: var(--efafafa);
            transition: .3s  all ease;
        }
    }
    &-green
    {
        background: rgb(0, 163, 49);
        &:hover
        {
            background: rgb(0, 129, 49);
            transition: .3s  all ease;
        }
    }
    &-red
    {
        background: rgb(163, 0, 14);
        &:hover
        {
            background: rgb(116, 0, 10);
            transition: .3s  all ease;
        }
    }
    &-blue
    {
        background: var(--eblue);
        &:hover
        {
            background: var(--edarkblue);
            transition: .3s  all ease;
        }
    }
    &-dark
    {

        background: rgb(109, 109, 109);
        &:hover
        {
            background: rgb(78, 78, 78);
            transition: .3s  all ease;
        }

    }
    &-viol
    {
        background: rgb(103, 0, 163);

        &:hover
        {
            background: rgb(73, 6, 112);
            transition: .3s  all ease;
        }
    }
    &-discord
    {
        background: #4f66b7;
        display: block;
        height: 3rem;
        width: 11rem;
        margin: auto;
        img
        {
            max-width: 100%;
            max-height: 100%;
            display: block;
            margin: auto;
        }
        &:hover
        {
            background: #394b8b;
            transition: .3s  all ease;
        }
    }
    &-youtube
    {
        background: #cf0000;
        display: block;
        height: 3rem;
        width: 11rem;
        margin: auto;
        img
        {
            max-width: 100%;
            max-height: 100%;
            display: block;
            margin: auto;
        }
        &:hover
        {
            background: #bb0000;
            transition: .3s  all ease;
        }
    }
    &-telegram
    {
        background: #2b91bf;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 3rem;
        width: 11rem;
        margin: auto;
        color: #fafafa;
        img
        {
            max-width: 100%;
            max-height: 100%;
            display: block;
            margin: auto;
        }
        &:hover
        {
            background: #2782ac;
            transition: .3s  all ease;
        }
    }
    background: rgb(160, 160, 160);
    border: 0px;
    border-radius: 3px;
    color: #fff;
    padding: 7px;
    font-weight: bold;
    transition: .3s  all ease;
    text-decoration: none;
    min-height: 40px;
    margin: 0 0.2rem;
    &.big
    {
        font-size: 1.3rem;
        padding: 0.5rem;
    }
}
