.class
{
    font-weight: 600;
    padding: 5px 11px;
    width: fit-content;
    display: block;
    transform: skewX(-12deg);
    text-transform: uppercase;
    &-gt3
    {
        background: #000000;
        color: #ffffff;
        
    }
    &-GT4
    {
        background: #020fc2;
        color: #ffffff;
        
    }
    &-GTC
    {
        background: #20aa0e;
        color: #ffffff;
    }
    &-ST
    {
        background: #ffc400;
        color: #111111;
    }
} 