.loader{
    display: block;
    position: relative;
    height: 20px;
    width: 140px;
    background-image: 
    linear-gradient(var(--eh-color) 20px, transparent 0), 
    linear-gradient(var(--eh-color) 20px, transparent 0), 
    linear-gradient(var(--eh-color) 20px, transparent 0), 
    linear-gradient(var(--eh-color) 20px, transparent 0);
    background-repeat: no-repeat;
    background-size: 20px auto;
    background-position: 0 0, 40px 0, 80px 0, 120px 0;
    animation: pgfill 1s linear infinite;
    &-container
    {
        width: 100%;
        display: flex;
        flex-direction: column;

        padding: 1rem;
        justify-content: center;
        align-items: center;
        h3 
        {
          animation: status 1.5s ease-in-out infinite;
        }
    }
  }
  @keyframes pgfill {
    0% {   background-image: linear-gradient(var(--eh-color) 20px, transparent 0), linear-gradient(var(--eh-color) 20px, transparent 0), linear-gradient(var(--eh-color) 20px, transparent 0), linear-gradient(var(--eh-color) 20px, transparent 0); }
    25% {   background-image: linear-gradient(var(--ebluehover) 20px, transparent 0), linear-gradient(var(--eh-color) 20px, transparent 0), linear-gradient(var(--eh-color) 20px, transparent 0), linear-gradient(var(--eh-color) 20px, transparent 0); }
    50% {   background-image: linear-gradient(var(--eh-color) 20px, transparent 0), linear-gradient(var(--ebluehover) 20px, transparent 0), linear-gradient(var(--eh-color) 20px, transparent 0), linear-gradient(var(--eh-color) 20px, transparent 0); }
    75% {   background-image: linear-gradient(var(--eh-color) 20px, transparent 0), linear-gradient(var(--eh-color) 20px, transparent 0), linear-gradient(var(--ebluehover) 20px, transparent 0), linear-gradient(var(--eh-color) 20px, transparent 0); }
    100% {   background-image: linear-gradient(var(--eh-color) 20px, transparent 0), linear-gradient(var(--eh-color) 20px, transparent 0), linear-gradient(var(--eh-color) 20px, transparent 0), linear-gradient(var(--ebluehover) 20px, transparent 0); }
  }
  @keyframes status {
    0% {opacity: 1;}
    50% {opacity: 0.5;}
    100% {opacity: 1;}
  }