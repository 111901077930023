.profile
{

    background: var(--ebg-gradient);
    margin-top: 0px;
    padding-top: 12rem;
    padding-bottom: 80px;
    .errors
    {
        position: fixed;
        top: 4rem;
        left: 2rem;
        width: 400px;
        max-width: calc(100% - 4rem);
        z-index: 3;
        .error
        {
            box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.75);
            a
            {
                text-align: center;
                display: block;
            }
        }
    }
    &-main
    {
        background: var(--ebg-color);
        //padding-top: 60px;
        margin-top: 0;
    }
    &-avatar
	{
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
        justify-content: center;
        position: relative;
        top: -6rem;
		&__btn
		{
			position: static!important;
			color: var(--ebg-color)!important;
			cursor: pointer;
		}
		img
		{
			width: 180px;
			height: 180px;
			object-fit: cover;
			border-radius: 50%;
			-webkit-box-shadow: 1px -5px 10px 0px #4c4c4c;;
			-moz-box-shadow: 1px -5px 10px 0px #4c4c4c;;
			box-shadow: 1px -5px 10px 0px #4c4c4c;;
		}
    }
    &-ban
    {

        margin: 1rem auto;
        span
        {
            display: block;
            background: red;
            padding: 0.3rem;
            text-align: center;
            font-family: 'OpenSans-Bold';
            color: #fff;
            max-width: 300px;
            margin: auto;
            border-radius: 0.3rem;
        }
    }
    &-name
    {
        margin-top: -6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        h2
        {
            display: block;
        }
        span
        {
            display: block;
            height: 2.2rem;
            margin-left: 2rem;
        }

    }
    &-links
	{
		display: flex;
		justify-content: space-between;
		font-size: 50px;
		width: 300px;
		max-width: 100%;
		margin: 10px auto;
		color: grey;
		transform: scale(1.0);
        transition: .3s all ease;
        margin-bottom: 2rem;
		a
		{
			&.profile-youtube
			{
				color: rgb(214, 0, 0);
			}
			&.profile-twitch
			{
				color: #9147ff;
			}
			&.profile-steam
			{
				color: #000;
			}
			&.profile-vk
			{
				color: #4a76a8;
            }
			&.profile-tg
			{
				color: #269bcd;
            }

			&:hover
			{
				transition: .3s all ease;
				transform: scale(1.1);
			}
		}
    }
    &-follow
    {
        padding: 0 2rem 2rem;
        display: flex;
        align-items: center;
        justify-content: center;

        &-btn
        {
            // margin-right: 2rem;
        }

    }
    &-from
    {
        font-family: 'OpenSans-SemiBold';
        color: #a3a3a3;
        margin: 1rem auto 1rem;
        p
        {
            text-align: center;
            margin: 0;
        }
    }
    &-favs
    {
        background: var(--efavs);
        padding: 2rem;
        img
        {
            max-width: 100%;
        }

    }
    &-car
    {
        background: var(--efafafa);
        border-radius: 1rem;
        box-shadow: 3px 7px 7px 0px var(--eprofile-shadow);
        position: relative;
        padding-top: 3rem;
        padding-bottom: 2rem;
        height: 250px;
        &__img
        {
            position: absolute;
            bottom: 2rem;
        }
        &-name
        {
            display: inline-block;
            position: absolute;
            top: 1rem;
            left: 1rem;
            img
            {
                max-width: 2.5rem;
            }
            h3
            {
                font-size: 0.8rem;
            }
        }
    }
    &-track
    {
        background: #fafafa;
        height: 250px;
        overflow: hidden;
        position: relative;
        border-radius: 1rem;
        box-shadow: 3px 7px 7px 0px var(--eprofile-shadow);


        &__bg
        {
            object-fit: cover;
            width: 100%;
            height: 100%;
            img
            {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
        &__map
        {
            position: absolute;
            top: 2rem;
            left: 2rem

        }
        &__name
        {
            position: absolute;
            top: 0;
            text-shadow: 2px 2px 1px rgb(70, 70, 70);
            color: var(--ebg-color);
        }
        &__flag
        {
            height: 1.2rem;
            width: 1.2rem;
            border-radius: 40%;
            margin-left: 1.3rem;
            box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.75);
        }
    }
    &-info
    {
        padding: 3rem;
        font-family: 'OpenSans-Regular';
        color: var(--eblack);
        &__label
        {
            font-family: 'OpenSans-SemiBold';
            width: 10rem;
        }
        &-teble
        {
            color: var(--eblack);
            tr
            {
                display: block;
                margin-bottom: 0.2rem;
            }
            td
            {
                vertical-align: top;

            }
        }
    }
    &-stattable
    {
        width: 100%;
        box-shadow: 0px 10px 11px -6px rgba(0, 0, 0, 0.1);
        margin-bottom: 3rem;
        color: var(--eblack);
        background: var(--eprotest-bg);
        thead
        {
            background: var(--ethead);
            color: var(--ebg-color);
            font-family: 'OpenSans-SemiBold';
        }
        tbody
        {
            font-family: 'OpenSans-Bold';
            text-align: center;
            color: var(--eblack);
        }
        td
        {
            border: 1px solid var(--ebg-color);
            padding: 0.8rem;

        }
        th
        {
            border: 1px solid var(--ebg-color);
            padding: 0.3rem;
        }
        .fa-caret-up
        {
            color: rgb(82, 128, 82);
            margin-left: 0.1rem;
        }
        .fa-caret-down
        {
            color: rgb(151, 60, 60);
            margin-left: 0.1rem;
        }
    }
    &-buttons
	{
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
        display: flex;
        justify-content: center;
    }
    &-premium
    {
        display: flex;
        justify-content: center;
        margin-bottom: 2em;
        &__link
        {
            @include button($blue, $bluehover);
		    border: none;
        }
    }
	&-results, &-edit
	{
		@include button($blue, $bluehover);
		border: none;
    }
    &-er
    {
        color: var(--eblack);
        position: relative;
        width: 300px;
        margin: auto;
        &-wrap
        {
            display: flex;
            align-items: center;
            margin: 1rem auto 2.5rem;
        }
        img
        {
            width: 2rem;
        }
        span
        {
            font-weight: 600;
            font-size: 1.7rem;
            height: initial;
            margin-left: .5rem;
            color: var(--eh-color);
        }
        &-rank
        {
            width: 200px;
            background: var(--ethead);
            height: 1.5rem;
            margin:  0 1rem;
            border-radius: .5rem;
            overflow: hidden;
            display: flex;
            justify-content: start;
            cursor: pointer;
            .sa
            {
                background: rgb(63, 13, 201);
            }
            .exp
            {
                background: #4778ce;
            }
            .sp
            {
                background: rgb(170, 13, 201);
            }
            .rc
            {
                background: rgb(201, 13, 44);
            }
        }
        &-meta
        {
            position: absolute;
            display: none;
            margin: auto;
            bottom: -1.0rem;
            font-family: 'OpenSans-SemiBold';
            text-align: center;
            width: 300px;
            font-size: 0.8rem;
        }
        &-more
        {
            display: flex;
            justify-content: center;
            margin-top: -2rem;
            &__link
            {
                font-size: 3rem;
                color: rgb(173, 169, 169);
                transition: all .3s ease;
                &:hover
                {
                    color: rgb(99, 95, 95);
                    transform: scale(1.1);
                    transition: all .3s ease;
                }
                &.toggle
                {
                    transform: rotate(-180deg);
                    transition: all .3s ease;
                }
            }
        }
        &-graph
        {
            overflow: hidden;
             height: 0;
            transform: scaleY(0);
            transform-origin: top;
            transition: all 1s ease;
            &.toggle
            {
                height: auto;
                transform: scaleY(1);
                transition: all .3s ease;
            }
        }
    }
    &-number
    {
        margin: 0em 1.5rem 0.5em;
        width: 2.5em;
        border: 2px solid #252525;
        padding: 0em 0em;
        font-size: 4em;
        text-align: center;
        border-radius: 0.3em;
        font-family: 'OpenSans-ExtraBold';
        overflow: hidden;
        -webkit-box-shadow: 0px 0px 15px -5px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 0px 15px -5px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 15px -5px rgba(0,0,0,0.75);
        color: var(--eblack);

        &-logo
        {
            background: #252525;
        }
        &-footer
        {
            font-family: 'OpenSans-Bold';
            font-size: 0.35em;
            text-transform: uppercase;
            color: #fafafa;
            background: #252525;
        }
    }
    &-team
    {
        margin: 0em 1.5rem 0.5em;
        width: 2.5em;
        height: 152px;
        padding: 0em 0em;
        font-size: 4em;
        text-align: center;
        border-radius: 0.3em;
        font-family: 'OpenSans-ExtraBold';
        overflow: hidden;
        -webkit-box-shadow: 0px 0px 15px -5px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 0px 15px -5px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 15px -5px rgba(0,0,0,0.75);
        position: relative;
        img
        {
            object-fit: contain;
            width: 100%;
            height: 100%;
        }
        a
        {
            transform: scale(1);
            transition: all .2s ease-in-out;
            &:hover
            {
                transform: scale(1.1);
                transition: all .2s ease-in-out;

            }
        }
    }
    &-premiums
    {
        display: flex;
        justify-content: center;
    }
    &-ext-stat
    {
        overflow: hidden;
        height: 0;
       transform: scaleY(0);
       transform-origin: top;
       transition: all 1s ease;
       &.toggle
       {
           height: auto;
           transform: scaleY(1);
           transition: all .3s ease;
       }
        &__btn
        {
            text-align: center;
            margin: 1rem;
            a
            {
                font-family: 'OpenSans-Bold';
                font-size: 1.5rem;
                color: rgb(173, 169, 169);
                text-decoration: none;
                transition: all .2s ease;
                &:hover
                {
                    color: rgb(99, 95, 95);
                    transform: scale(1.1);
                    transition: all .2s ease;
                }
                i
                {
                    transition: all .2s ease;
                    &.toggle
                    {
                        transition: all .2s ease;
                        transform: rotate(-180deg);
                    }
                }
            }
        }
        .profile-stattable
        {
            tbody tr
            {
                &:hover
                {
                    background: var(--ealmost-white);
                }
            }
        }
    }
    &-modal
	{
		background: rgba(0, 0, 0, 0.356);
		position: fixed;
		top:0;
		width: 100vw;
		height: 100%;
		z-index: 10;
		display: flex;
		justify-content: center;
		align-items: center;
        backdrop-filter: blur(5px);
		&-item
		{
            max-width: 90%;
            position: relative;

			width: 60rem;
			padding: 0rem;

            border-radius: 0.3rem;
            overflow: hidden;
            .close
            {
                position: absolute;
                top: 1rem;
                right: 1.5rem;
                z-index: 100;
                color:rgb(70, 70, 70);
                font-size: 1.3rem;
                color: #707070;
                &:hover
                {
                    color:#000;
                    cursor: pointer;
                }
            }
			h2
			{
				margin-top: 0;
			}
			h2, h3, p
			{
				color: var(--ebg-color);
			}
            p
            {
                margin-bottom: calc(3.2rem + 14px);
            }
            @media screen and (max-width: 567px)
            {
                max-width: 95%;
                padding: 0 2rem;
                h2
                {
                    margin-bottom: 0.3rem;
                }
                p
                {
                    margin: 0.2rem 0rem;
                    margin-bottom: calc(3.2rem + 14px);
                }
            }

		}
        &-discord
        {
            background: #7289da;
            padding: 3rem;
            position: relative;
            .btn
            {
                position: absolute;
                bottom: 3rem;
            }
            @media screen and (max-width: 568px)
            {
                padding: 1rem;
                font-size: 1rem;
                .btn
                {
                    bottom: 1rem;
                }
            }
        }
        &-youtube
        {
            background: #FF0000;
            padding: 3rem;
            position: relative;
            .btn
            {
                position: absolute;
                bottom: 3rem;
            }
            @media screen and (max-width: 568px)
            {
                padding: 1rem;
                font-size: 1rem;
                .btn
                {
                    bottom: 1rem;
                }
            }
        }
        &-telegram
        {
            background: #35a5da;
            padding: 3rem;
            position: relative;
            .btn
            {
                position: absolute;
                bottom: 3rem;
            }
            @media screen and (max-width: 568px)
            {
                padding: 1rem;
                font-size: 1rem;
                .btn
                {
                    bottom: 1rem;
                }
            }
        }
        &-btnarea
        {
            width: 100%;
            display: flex;
            justify-content: center;

        }
	}
    &-followers
    {
        &-link
        {
            text-decoration: none;
            transition: 0.2s all ease;
            transform: scale(1);
            h4
            {
                text-decoration: none;
            }
            &:hover
            {
                transform: scale(1.1);
                transition: 0.2s all ease;
            }
        }
    }
    &-achievs
    {
        width: 20rem;
        margin: auto;
    }
    &-steam-exist
    {
        .btn
        {
            font-size: 1.5rem;
            padding: 0.3rem 1rem;
            color: #fff;
        }
        a
        {
            color: #4778ce;
        }
        &-answer
        {
            display: none;
        }
    }
}
@media screen and (max-width:568px) {
    .profile
    {
        &-name
        {
            flex-direction: column;
            .category
            {
                margin: 0;
            }
            margin-bottom: 2em;
        }
        &-er
        {
            //margin: 1rem 0 1.2rem;
        }
    }

}
.rotate
{
    animation:  1s linear infinite rotation;
}
@keyframes rotation {
    from
    {
        transform: rotateZ(0);
    }
    to
    {
        transform: rotateZ(360deg);
    }
}
