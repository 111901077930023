
.header
{
	height: 70px;
	border-bottom: 2px solid var(--eheader-border);
	background: var(--ebg-color);
	-webkit-box-shadow: 2px 0px 17px 0px rgba(0,0,0,0.5);
	-moz-box-shadow: 2px 0px 17px 0px rgba(0,0,0,0.5);
	box-shadow: 2px 0px 17px 0px rgba(0,0,0,0.5);
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	align-content: center;
	align-items: center;
	position: fixed;
	width: 100vw;
	z-index: 9;
	display: flex;
	justify-content: space-between;
	&-after
	{
		content: '';
		position: static;
		height: 70px;
		width: 100%;
	}
	&-left
	{
		display: flex;
		flex: 2 300px;
		align-items: center;
	}
	&-right
	{
		flex-basis: 7.5rem;
		align-items: center;
		display: flex;
	}
	&-sign
	{
		flex-basis: 10rem;
		align-items: center;
		display: flex;
        &__btn
        {
            padding: 10px 20px!important;
            box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 3px 2px;

        }
	}
	&-notification
	{
		color: #707070;
		display: flex;
		justify-content: center;
		font-size: 1.5rem;
		position: relative;
        div
        {
            &:first-child
            {
                flex: 0 0 3rem;
            }

        }
		&__icon
		{
			margin-right: 15px;
			font-size: 1.3rem;
			margin-top: 6px;
		}
		&__img
		{
			margin-right: 15px;
			font-size: 1.3rem;
			margin-top: 6px;
            max-width: 25px;
		}
		&__new
		{
			width: 0.5rem;
			height: 0.5rem;
			border-radius: 50%;
			background: red;
			position: absolute;
			top: 0;
			right: 0;
		}
		#header-notification
		{
			color: #707070;

		}
		.content
		{
            background: var(--ebg-color);
            color: var(--eh-color);
			h4
			{
				background: var(--ealmost-white);
				margin: 0;
				padding: 10px;
				position: fixed;
				width: 20rem;
				height: 2.5rem;
			}
			max-height: 80vh;
			display: none;
			overflow-y: auto;
			position: absolute;
			right: 3rem;
			//margin-top: -2rem;
			width: 20rem;
			font-size: 1rem;

			-webkit-box-shadow: 1px 0px 5px 0px rgba(0,0,0,0.5);
			-moz-box-shadow: 1px 0px 5px 0px rgba(0,0,0,0.5);
			box-shadow: 1px 0px 5px 0px rgba(0,0,0,0.5);
			&.active
			{
				display: block;
			}
			ul
			{
				list-style: none;
				padding: 0;
				margin: 2.5rem 0 0 0;
				li
				{
					display: block;
					a
					{
						padding: 1em 1.2em;
						display: flex;
						text-decoration: none;
						color: #707070;
						font-weight: 500;
						font-family: 'OpenSans-SemiBold';
						font-size: 0.8rem;
						&:hover
						{
                            background: rgba(186, 185, 226, 0.3);
						}
                        .bold
                        {
                            font-family: 'OpenSans-Bold';

                        }
					}
				}
			}
			&__none
			{
				color: #707070;
				text-align: center;
				font-family: 'OpenSans-SemiBold';
				font-size: 0.8rem;
				padding: 1.7rem 0;
			}
		}
		p
		{
			margin: 0;
		}
		.time
		{
			font-family: 'OpenSans-Bold';
						font-size: 0.6rem;
		}
	}
	&-nav
	{
		font-size: 35px;
		margin: 30px;
		a, a:hover
		{
			text-decoration: none;
			color: #707070;
		}
		@media screen and (max-device-width: 768px) {
			display: none;
		}
		@media screen and (max-device-height: 568px) {
			display: none;
		}
	}
	&-logo
	{
		width: 185px;
		height: auto;
		max-width: 100%;
		margin: 10px;
	}
}
@media screen and (max-width: 576px)
{
	.header
	{
		&-notification
		{
			.content
			{
				right: -3rem;
				width: 16rem;
    			top: 2rem;
				h4
				{
					width: 16rem;
				}
			}
		}
		&-nav
		{
			margin: 30px 10px;
		}
		&-right
		{
			flex-basis: 5.5rem;
		}
	}
}
