.setuphub
{
    &-items
    {
        width: 90%;
        box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.3);
        border-radius: 3px;
        margin-bottom: 1.5rem;
        transition: all .3s ease;
        max-height: 20rem;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 8px;               /* width of the entire scrollbar */
          }
        &::-webkit-scrollbar-thumb {
            background-color: rgb(128, 128, 128); 
            border-radius: 5px;   /* color of the scroll thumb */
              /* creates padding around scroll thumb */
          }
    }
    &-item
    {
        padding: 0.2rem 0.5rem;
        display: flex;        
        border-bottom: 2px solid #c9c9c9;
        align-items: center;
        cursor: pointer;
        width: 100%;
        &__icon
        {
            width: 40px;
            height: 40px;
            
            overflow: hidden;
            margin-left: 1rem;
            margin-right: 1.5rem;
            img 
            {
                object-fit: contain;
                width: 100%;
                height: 100%;
            }
        }
        p 
        {
            font-family: 'OpenSans-SemiBold';
        }
        &:hover 
        {
            background: rgb(241, 241, 241);
        }
    }
    &-selected-item
    {
        padding: 0.2rem 0.5rem;
        display: flex;
        box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.3);
        border-radius: 3px;
        align-items: center;
        width: 100%;
        margin-bottom: 2rem;
        position: relative;
        &__icon
        {
            width: 40px;
            height: 40px;
            overflow: hidden;
            margin-left: 1rem;
            margin-right: 1.5rem;
            img 
            {
                object-fit: contain;
                width: 100%;
                height: 100%;
            }
        }
        p 
        {
            font-family: 'OpenSans-SemiBold';
        }
        &__close
        {
            position: absolute;
            right: 1rem;
            top: 1rem;
            position: absolute;
            right: 1rem;
            bottom: 50%;
            transform: translateY(50%);
            cursor: pointer;
        }
    }    
    &-submit
    {
        margin: auto;
    }
}